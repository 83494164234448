* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:focus,
  &:active {
    outline: none !important;
  }
}
a {
  cursor: pointer;
}
svg {
  vertical-align: top;
}
