@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import "./assets/scss/variables";
@import "./assets/scss/mixins";
@import "./assets/scss/base";
@import "./assets/scss/misc";
@import "bootstrap/scss/bootstrap";

html,
body {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  color: #333;
}
.f16 {
  font-size: 16px;
}

.column {
  padding: 0;
}

.row {
  max-width: none;
}
.no-margin {
  margin: 0;
}
.no-margin-imp {
  margin: 0 !important;
}
.row .row {
  margin: 0;
}
.show-mob,
.show-tab {
  display: none !important;
}
.c-000 {
  color: #000;
}
.c-555 {
  color: #555;
}
.c-777 {
  color: #777;
}
.c-999 {
  color: #999;
}
.bg-fff {
  background-color: #fff;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.space-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.page-link {
  margin: 0 4px !important;
}
.underline {
  text-decoration: underline;
}
.no-decoration {
  text-decoration: none !important;
}
.f400 {
  font-weight: 400;
}
.f500 {
  font-weight: 500;
}
.f600 {
  font-weight: 600;
}
.fnt-12 {
  font-size: 12px;
}
.fnt-13 {
  font-size: 13px;
}
.fnt-14 {
  font-size: 14px;
}
.fnt-15 {
  font-size: 15px;
}
.fnt-16 {
  font-size: 16px;
}
.fnt-17 {
  font-size: 17px;
}
.fnt-18 {
  font-size: 18px;
}
.fnt-19 {
  font-size: 19px;
}
.fnt-20 {
  font-size: 20px;
}
.fnt-21 {
  font-size: 21px;
}
.fnt-22 {
  font-size: 22px;
}
.fnt-23 {
  font-size: 23px;
}
.fnt-24 {
  font-size: 24px;
}
.fnt-25 {
  font-size: 25px;
}
.fnt-26 {
  font-size: 26px;
}
.fnt-27 {
  font-size: 27px;
}
.h100 {
  height: 100%;
}
.mar-15 {
  margin: 15px;
}
.mar-30 {
  margin: 30px;
}
.mar-top-1 {
  margin-top: 1px;
}
.mar-top-2 {
  margin-top: 2px;
}
.mar-top-3 {
  margin-top: 3px;
}
.mar-top-4 {
  margin-top: 4px;
}
.mar-top-5 {
  margin-top: 5px;
}
.mar-top-7 {
  margin-top: 7px;
}
.mar-top-10 {
  margin-top: 10px;
}
.mar-top-12 {
  margin-top: 12px;
}
.mar-top-15 {
  margin-top: 15px;
}
.mar-top-17 {
  margin-top: 17px;
}
.mar-top-18 {
  margin-top: 18px;
}
.mar-top-20 {
  margin-top: 20px;
}
.mar-top-25 {
  margin-top: 25px;
}
.mar-top-30 {
  margin-top: 30px;
}
.mar-top-35 {
  margin-top: 35px;
}
.mar-top-40 {
  margin-top: 40px;
}
.mar-top-45 {
  margin-top: 45px;
}
.mar-top-45-imp {
  margin-top: 45px !important;
}
.mar-top-50 {
  margin-top: 50px;
}
.mar-top-90 {
  margin-top: 90px;
}
.mar-bot-0 {
  margin-bottom: 0px;
}
.mar-bot-5 {
  margin-bottom: 5px;
}
.mar-bot-8 {
  margin-bottom: 8px;
}
.mar-bot-10 {
  margin-bottom: 10px;
}
.mar-bot-15 {
  margin-bottom: 15px;
}
.mar-bot-20 {
  margin-bottom: 20px;
}
.mar-bot-25 {
  margin-bottom: 25px;
}
.mar-bot-30 {
  margin-bottom: 30px;
}
.mar-bot-35 {
  margin-bottom: 35px;
}
.mar-bot-40 {
  margin-bottom: 40px;
}
.mar-bot-25-imp {
  margin-bottom: 25px !important;
}
.margin-tb-16 {
  margin: 16px 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-margin-rht {
  margin-right: 0;
}
.no-margin-lft {
  margin-left: 0;
}
.no-margin {
  margin: 0 !important;
}
.no-margin-lr {
  margin-left: 0;
  margin-right: 0;
}
.no-padding-top {
  padding-top: 0;
}
.no-padding-bot {
  padding-top: 0;
}
.no-padding-left {
  padding-left: 0;
}
.no-padding-right {
  padding-right: 0;
}
.pad-30 {
  padding: 30px;
}
.pad-lft-10 {
  padding-left: 10px;
}
.pad-rht-10 {
  padding-right: 10px;
}
.no-padding-lr {
  padding-left: 0;
  padding-right: 0;
}
.no-padding {
  padding: 0;
}
.no-padding-imp {
  padding: 0 !important;
}
.pad-10 {
  padding: 10px;
}
.pad-15 {
  padding: 15px;
}
.pad-20 {
  padding: 20px;
}
.pad-top-2 {
  padding-top: 2px;
}
.pad-top-3 {
  padding-top: 3px;
}
.pad-top-4 {
  padding-top: 4px;
}
.pad-top-5 {
  padding-top: 5px;
}
.pad-top-6 {
  padding-top: 6px;
}
.pad-top-7 {
  padding-top: 7px;
}
.pad-top-8 {
  padding-top: 8px;
}
.pad-top-9 {
  padding-top: 9px;
}
.pad-top-10 {
  padding-top: 10px;
}
.pad-top-11 {
  padding-top: 11px;
}
.pad-top-12 {
  padding-top: 12px;
}
.pad-top-13 {
  padding-top: 13px;
}
.pad-top-14 {
  padding-top: 14px;
}
.pad-top-15 {
  padding-top: 15px;
}
.pad-top-16 {
  padding-top: 16px;
}
.pad-top-20 {
  padding-top: 20px;
}
.pad-top-25 {
  padding-top: 25px;
}
.pad-top-30 {
  padding-top: 30px;
}
.pad-top-35 {
  padding-top: 35px;
}
.pad-top-40 {
  padding-top: 40px;
}
.pad-top-45 {
  padding-top: 45px;
}
.pad-bot-5 {
  padding-bottom: 5px;
}
.pad-bot-10 {
  padding-bottom: 10px;
}
.pad-bot-15 {
  padding-bottom: 15px;
}
.pad-bot-20 {
  padding-bottom: 20px;
}
.pad-bot-25 {
  padding-bottom: 25px;
}
.pad-bot-30 {
  padding-bottom: 30px;
}
.pad-bot-35 {
  padding-bottom: 35px;
}
.pad-bot-40 {
  padding-bottom: 40px;
}
.pad-bot-45 {
  padding-bottom: 45px;
}
.pad-0-15 {
  padding: 0 15px;
}
.cfff {
  color: #fff;
}
.c333 {
  color: #333;
}
.c333-imp {
  color: #333 !important;
}
.c444 {
  color: #444;
}
.c555 {
  color: #555;
}
.cfff {
  color: #fff;
}
.alert-danger {
  background-color: inherit;
  border: inherit;
  font-weight: 500;
  font-size: 11px;
  color: #bf0012;
  padding: 0;
  padding-left: 17px;
}
a {
  color: #740316;
  text-decoration: none;
}
.mock-link.active,
.mock-link:active,
.mock-link:hover,
a.active,
a:active,
a:hover {
  color: #b20522;
}
.error::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fa4a5b;
  opacity: 1; /* Firefox */
}

.error:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fa4a5b;
}

.error::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fa4a5b;
}
.error {
  color: #fa4a5b !important;
}
.btn-theme {
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-radius: 4px !important;
  background-color: #ed1164 !important;
  font-size: 14px;
  font-weight: bold !important;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff !important;
  padding: 8px 16px;
  min-width: 160px;
}
p {
  font-weight: 400 !important;
}
.block {
  display: block;
}
.left {
  float: left;
}
.right {
  float: right;
}
.form-control {
  font-size: 14px;
}
.static {
  position: static;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.space-around {
  justify-content: space-between;
}
.space-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.noPadding {
  padding: 0 !important;
}
.no-padding-left {
  padding-left: 0 !important;
}
.no-padding-right {
  padding-right: 0 !important;
}
.pd-tb10 {
  padding: 10px 0;
}
.pad-t-12 {
  padding-top: 12px;
}
.pad-t-20 {
  padding-top: 20px;
}
.plr-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.pd-b5 {
  padding-bottom: 5px;
}
.pd-l7 {
  padding-left: 7px !important;
}
.pd-r7 {
  padding-right: 7px !important;
}
.pd-bt-20 {
  padding-bottom: 20px;
}
.width-100 {
  width: 100% !important;
}
.pointer {
  cursor: pointer;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.transform-180 {
  transform: rotate(-180deg);
}
/* homepage banner slide */
.top-bg .slick-slide {
  height: 100vh;
  min-height: 650px;
}
.img-info {
  z-index: 1111;
  position: absolute;
  bottom: 30px;
  color: #fff;
  width: 100%;
  font-size: 13px;
  line-height: 1.6;
  opacity: 0.7;
}
.img-info a {
  color: #fff !important;
}

/* homepage Slick corosal */ /* headout homepage Slick corosal */
.more-campaign .slick-list {
  padding: 0 !important;
}
.more-campaign .slick-prev,
.cards-sec .slick-prev {
  left: auto;
  right: 48px;
  top: -30px;
}
.more-campaign .slick-next,
.cards-sec .slick-next {
  left: auto;
  right: 12px;
  top: -30px;
}
.block-slot-container .slick-prev {
  left: auto;
  right: 35px;
  top: -15px;
}
.block-slot-container .slick-next {
  left: auto;
  right: 0px;
  top: -15px;
}
.block-slot-container .slick-prev.slick-disabled:before,
.block-slot-container .slick-next.slick-disabled:before {
  opacity: 0.75;
}
.more-campaign .slick-prev:before,
.cards-sec .slick-prev:before,
.more-campaign .slick-next:before,
.cards-sec .slick-next:before,
.block-slot-container .slick-prev:before,
.block-slot-container .slick-next:before {
  color: #aaa !important;
  font-size: 23px;
}
.block-slot-container .slick-prev:before,
.block-slot-container .slick-next:before {
  font-size: 18px;
}
.more-campaign .slick-prev:hover:before,
.cards-sec .slick-prev:hover:before,
.more-campaign .slick-next:hover:before,
.cards-sec .slick-next:hover:before,
.more-campaign .slick-prev:focus:before,
.cards-sec .slick-prev:focus:before,
.more-campaign .slick-next:focus:before,
.cards-sec .slick-next:focus:before {
  color: #ed1164 !important;
}

.list-left {
  height: fit-content;
}

/* hotel details  */
.hotel-detail-mid .slider-for .slick-prev {
  left: 5px;
  z-index: 1;
}
.hotel-detail-mid .slider-for .slick-next {
  right: 5px;
  z-index: 1;
}
.hotel-detail-mid .slider-nav .slick-slide,
.slider-nav .time-slide {
  padding: 0 3px;
  height: 70px;
}
.time-slide {
  float: left;
  min-height: 1px;
  width: 80px !important;
  flex: 0 0 80px;
}
.select-date-container .slick-track {
  display: flex;
}
.hotel-detail-mid .slider-nav .slick-list {
  padding: 0px 25px !important;
}
.hotel-detail-mid .slider-nav .slick-prev:before,
.hotel-detail-mid .slider-nav .slick-next:before {
  color: #000;
  opacity: 0.5;
}
.hotel-detail-mid .slider-for .slick-prev:before,
.slider-for .slick-next:before {
  content: "\f104";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 22px;
  margin-left: 12px;
}
.hotel-detail-mid .slider-for .slick-next {
  transform: rotate(180deg) translate(0, 50%);
}

.hotel-detail-mid .slider-for .slick-prev,
.hotel-detail-mid .slider-for .slick-next {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  background-color: rgba(17, 17, 17, 0.4);
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 21px;
  height: 32px;
  padding: 0;
  position: absolute;
  top: 50%;
  transition: 0.2s background-color;
  width: 33px;
  z-index: 1;
  color: transparent;
}
.hotel-detail-mid .slider-for .slick-prev:hover,
.hotel-detail-mid .slider-for .slick-prev:focus,
.hotel-detail-mid .slider-for .slick-next:hover,
.hotel-detail-mid .slider-for .slick-next:focus {
  background: rgba(17, 17, 17, 0.5);
}
.hotel-detail-mid .slider-nav .slick-prev:hover,
.hotel-detail-mid .slider-nav .slick-prev:focus,
.hotel-detail-mid .slider-nav .slick-next:hover,
.hotel-detail-mid .slider-nav .slick-next:focus {
  background-color: #fff;
}
.hotel-detail-mid .slider-for .slick-prev:hover:before,
.hotel-detail-mid .slider-for .slick-prev:focus:before,
.hotel-detail-mid .slider-for .slick-next:hover:before,
.hotel-detail-mid .slider-for .slick-next:focus:before {
  opacity: 0.65;
}
.hotel-detail-mid .slider-nav .slick-prev,
.hotel-detail-mid .slider-nav .slick-next {
  height: 70px;
  width: 25px;
  background-color: #fff;
  left: 0;
  z-index: 1;
}
.hotel-detail-mid .slider-nav .slick-next {
  left: unset;
  right: 0;
}
.hotel-detail-fixed-box .DateRangePicker__picker {
  right: -21px;
  left: inherit !important;
  top: 52px;
}
/* datepicker */
.fixed-box .DateInput:first-child {
  padding-left: 5px !important;
}
.fixed-box .DateInput {
  padding: 5px;
  font-size: 12px !important;
  width: 90px !important;
  padding-bottom: 5px !important;
}
.fixed-box .DateInput__display-text {
  padding: 3px !important;
  height: 28px;
}
.fixed-box .DateRangePickerInput__arrow {
  margin-top: -1px;
}
.fixed-box .DateRangePickerInput__clear-dates {
  padding: 4px !important;
  margin: 9px 3px 0 0 !important;
}
.fixed-box .DateRangePicker {
  margin-top: 10px;
}
.DateRangePickerInput {
  border-radius: 3px;
  overflow: hidden;
  width: 100%;
}
.DateRangePicker {
  width: 100%;
}
.DateRangePicker__picker {
  z-index: 2;
}
.DateInput {
  padding: 4px;
  font-size: 14px;
}
.DateRangePickerInput {
  border: solid 1px rgba(78, 74, 112, 0.12);
}
.DateRangePickerInput__arrow svg {
  width: 20px;
}
.SingleDatePicker {
  width: 100%;
}
.SingleDatePicker .DateInput {
  padding: 4px !important;
  width: 100% !important;
}
.SingleDatePicker .DateInput__display-text--focused {
  background: transparent;
}
.SingleDatePicker .DateInput:first-child .DateInput__display-text {
  padding-left: 32px !important;
  text-align: left;
}
.SingleDatePicker .DayPicker .transition-container {
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.headout-offer-box .SingleDatePickerInput {
  border-radius: 3px;
}
.more-date .SingleDatePickerInput .DateInput__input {
  height: 60px !important;
  cursor: pointer;
}
.more-date .SingleDatePickerInput {
  opacity: 0;
}
.more-date .SingleDatePicker {
  border: 0 !important;
}
/*   */
.button13 {
  color: rgba(255, 255, 255, 1);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
}
.button13 a {
  color: rgba(51, 51, 51, 1);
  text-decoration: none;
  display: block;
}
.button13::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.button13:hover::before {
  opacity: 0;
  -webkit-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}
.button13::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  /*background-color: rgba(255,255,255,0.25);*/
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
}
.button13:hover::after {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}
.site-header {
  position: fixed;
}
.default-head .site-header {
  position: fixed;
}
.sticky .color-img,
.default-head .color-img {
  display: inline-block !important;
}
.default-img-mob,
.color-img-mob {
  display: none !important;
}
.sticky .default-img,
.default-head .default-img {
  display: none !important;
}
.sticky,
.default-head .site-header {
  background-color: #fff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.sticky .menu-item,
.default-head .menu-item,
.sticky .header-menu .menu-item > a,
.default-head .header-menu .menu-item > a {
  color: #333 !important;
}
.sticky a.signup-btn,
.default-head a.signup-btn,
.signup-btn span {
  color: #fff !important;
}
.menu-item > a {
  color: #fff !important;
  text-decoration: none;
}

.sticky .search-input,
.default-head .search-input {
  box-shadow: 0 0 5px #ddd !important;
  background-color: #f8f8f8 !important;
  color: #333 !important;
}
.sticky .search-input::placeholder,
.default-head .search-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999 !important;
  opacity: 1 !important; /* Firefox */
}
.sticky .search-input:-ms-input-placeholder,
.default-head .search-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999 !important;
}
.sticky .search-input::-ms-input-placeholder,
.default-head .search-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999 !important;
}
.sticky .search-container svg,
.default-head .search-container svg {
  left: 42px !important;
}
.sticky .search-container svg path,
.default-head .search-container svg path {
  fill: #777 !important;
}

/* booking pages common code */
.top-box {
  height: 550px;
}
.top-box .bg-blk {
  background-color: rgba(0, 0, 0, 0.38);
  height: inherit;
  margin-top: -610px;
  z-index: 1;
  position: relative;
}
.flights-tagline {
  color: #fff;
  display: flex;
  padding-top: 100px;
  height: 400px;
  align-items: center;
}
.flights-tagline h2 {
  line-height: 1.3;
  margin: 0;
  padding: 0 20px;
  font-size: 50px;
  margin: 0 auto;
  text-align: center;
  font-size: 2.75rem;
}
.flights-tagline h3 {
  font-size: 36px;
}
.booking-bottom {
  margin-top: 65px;
  background-color: #fff;
}
.booking-bottom .container {
  padding: 40px 15px 25px;
}
.booking-bottom h3 {
  margin-bottom: 20px;
  font-weight: 500;
}
.booking-bottom .normal {
  color: #333 !important;
}
.booking-bottom p {
  color: rgba(51, 51, 51, 0.54);
  line-height: 1.78;
  font-size: 15px;
  font-weight: 500;
}
.booking-bottom .lnk {
  color: #0076bd !important;
  text-decoration: underline;
}
.booking-page {
  padding-bottom: 30px;
  background-color: #f2f2f2;
  min-height: calc(100vh - 196px);
}
/* custom grid */
.w16 {
  width: 16%;
}
.w24 {
  -ms-flex: 0 0 24%;
  -webkit-box-flex: 0;
  flex: 0 0 24%;
  max-width: 24%;
}
.w28 {
  width: 28%;
}
.w29 {
  width: 29%;
}
.w35 {
  width: 35%;
}
.w38 {
  -ms-flex: 0 0 38%;
  -webkit-box-flex: 0;
  flex: 0 0 38%;
  max-width: 38%;
}
.w65 {
  width: 65%;
}
.w100 {
  -ms-flex: 0 0 100%;
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
}
.btn-primary,
.btn-primary:hover {
  background-color: #7127fc !important;
  border-radius: 4px !important;
}

.divide-right {
  border-right: 1px solid #ddd;
}

.divide-left {
  border-left: 1px solid #ddd;
}
/*
.hidden-xs{
  display: block !important;
}*/
.visible-xs {
  display: none !important;
}
.flex {
  display: flex;
}
a {
  text-decoration: none;
  cursor: pointer;
}
.d-none {
  display: none;
}
.d-none-imp {
  display: none !important;
}
.noPadding {
  padding: 0 !important;
}
.no-padding-left {
  padding-left: 0 !important;
}
.no-padding-right {
  padding-right: 0 !important;
}
.pd-tb10 {
  padding: 10px 0;
}
.pad-t-12 {
  padding-top: 12px;
}
.plr-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.pd-b5 {
  padding-bottom: 5px;
}
.pd-l7 {
  padding-left: 7px;
}
.pd-r7 {
  padding-right: 7px;
}
.text-right {
  text-align: right !important;
}

.margin-top-zero {
  margin-top: 0px !important;
}

.margin-left-zero {
  margin-left: 0px !important;
}
.margin-right-zero {
  margin-right: 0px !important;
}
.right {
  float: right !important;
}
.left {
  float: left;
}
.block {
  display: block;
}
.hide {
  display: none;
}
.uppercase {
  text-transform: uppercase;
}
/*  */
.row-15 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}
.clear {
  clear: both;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}

.w18,
.w21,
.w24,
.w28,
.w33,
.w50 {
  float: left;
}
.w18 {
  width: 18%;
}
.w21 {
  width: 21%;
}
.w24 {
  width: 24%;
}
.w28 {
  width: 28%;
}
.w30 {
  width: 30%;
}
.w33 {
  width: 33%;
}
.w50 {
  width: 50%;
}
.more-offers {
  color: #b13a5e !important;
  height: 25px;
}
#tab_default_2 ul,
#tab_default_3 ul {
  list-style: none;
  padding: 0;
  margin: 0;
  counter-reset: li;
}
#tab_default_2 li::before {
  content: "\2022 ";
  opacity: 0.34;
  font-size: 35px;
  color: #333;
  position: absolute;
  top: -14px;
  left: -1px;
}
#tab_default_2 li {
  position: relative;
  padding-left: 32px;
  line-height: 1.5;
  padding-bottom: 8px;
}
#tab_default_3 li {
  position: relative;
  padding-left: 40px;
  line-height: 1.5;
  padding-bottom: 8px;
  counter-increment: li;
}
#tab_default_3 li::before {
  content: counter(li);
  color: red;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  background-color: #b13a5e;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  font-size: 10px;
  padding-top: 3px;
  left: 12px;
  position: absolute;
}

.page_pagination {
  padding-top: 16px;
}
.page_pagination ul {
  padding-left: 0px !important;
}
.page_pagination li:first-child {
  padding: 0;
}
.page_pagination li {
  display: inline;
  padding: 0 4px;
}
.page_pagination .border-pagination {
  background-color: #4e4a70;
  display: inline-block;
  height: 23px;
  opacity: 0.3;
  padding: 0;
  vertical-align: top;
  width: 1px;
}
.page_pagination li a {
  text-decoration: none;
  color: #b13a5e !important;
  font-weight: 600;
  font-size: 13px;
}
.modal-bg {
  height: auto;
  width: 470px;
  border-radius: 8px !important;
  background-color: #ffffff;
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.12),
    0 5px 10px 0 rgba(0, 0, 0, 0.12);
  margin-top: 5%;
  padding: 0px 80px 24px;
  background-image: url(https://extraordinaryweekends.com/assets/popbg-cb5a59858eacd061550a49074cb9b056ec3186a8bd780d3c8aff7a59f040ada3.png) !important;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
}
#update-booking-detail .mat-form-field-flex {
  border: 1px solid rgba(77, 73, 112, 0.24) !important;
  padding-left: 10px;
}
#update-booking-detail
  .mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label,
#update-booking-detail
  .mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  display: none !important;
}
#update-booking-detail .mat-form-field-label-wrapper {
  top: -16px;
}
#update-booking-detail .mat-input-element,
#update-booking-detail .mat-form-field-suffix {
  top: -5px;
  position: relative;
}
#update-booking-detail .mat-form-field-underline {
  background-color: unset;
}
.tag {
  outline: none;
  background-color: #ec407a !important;
  border-radius: 18px !important;
  font-size: 12px !important;
  box-shadow: none !important;
  text-transform: inherit;
  text-transform: none;
}
.customize-offer {
  width: 100%;
  height: 400px;
  position: relative;
  background-image: url(/assets/customize-offers.png);
}
.customize-offer .container {
  display: table;
  height: 100%;
}
.customize-offer-inner {
  display: table-cell;
  vertical-align: middle;
}
.personalize-offer-head {
  font-size: 42px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.02;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 20px;
}
.personalize-offer-description {
  font-size: 17px;
  width: 30%;
  font-weight: 600;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.87);
  line-height: 1.72;
}
.personalize-offer-button {
  background-color: #fff !important;
  color: #b13a5e !important;
  font-size: 12px;
  font-weight: 600;
  margin: 20px 0 0;
  box-shadow: none;
  text-transform: none;
}
.retail-detail-description p {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.pagination li a {
  border-radius: 50% !important;
  margin: 0 6px;
  box-sizing: border-box;
  height: 29px;
  width: 29px;
  line-height: 30px !important;
  padding: 0 !important;
  text-align: center;
  font-size: 12px;
  color: rgba(74, 74, 74, 0.54) !important;
  border: 0 !important;
  display: block;
}
.pagination-outer {
  margin: 10px auto 20px;
}
.pagination li:first-child a,
.pagination li:last-child a {
  border: 1px solid #ddd !important;
  background-color: #fafafa;
}
.pagination li:first-child a span,
.pagination li:last-child a span {
  position: relative;
  top: -2px;
  font-size: 16px;
}
.pagination li.active a {
  background-color: #b13a5e !important;
  color: #fff !important;
  border: 0;
}
.radio-primary input[type="radio"] + label::after {
  border: 5px solid #b13a5e !important;
  background: #fff !important;
  width: 16px !important;
  height: 16px !important;
  top: 0px !important;
  right: 0px !important;
}
/* [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before {
    content: '' !important;
    width: 16px !important;
    height: 16px !important;
    border: 1px solid #bbb !important;
    border-radius: 100% !important;
    background: #fff !important;
} */
.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #b13a5e !important;
  border-color: #b13a5e !important;
}
.card-bottom ul,
.card-bottom ol,
.annual-fee-container ul,
.annual-fee-container ol .benifits ul,
.benifits ol,
.bullet-point,
.bullet-point ul,
.bullet-point ol {
  list-style: none;
  padding-left: 0;
}
.card-bottom li,
.annual-fee-container li,
.benifits li,
.earnings li,
.bullet-point li {
  position: relative;
  padding-left: 32px;
  line-height: 1.5;
  padding-bottom: 8px;
  color: #333;
}
.card-bottom li::before,
.annual-fee-container li::before,
.benifits li::before,
.earnings li::before,
.bullet-point li::before {
  content: "\2022 ";
  opacity: 0.34;
  font-size: 35px;
  color: #333;
  position: absolute;
  top: -14px;
  left: -1px;
}
.card-bottom li li,
.benifits li li,
.earnings li li,
.bullet-point li li {
  padding-left: 25px;
}
.card-bottom li li:first-child {
  margin-top: 10px;
}
.stick-compare {
  position: fixed !important;
  top: 0 !important;
  background-color: #fff !important;
  z-index: 1 !important;
}
.benifits table td {
  font-weight: 300;
  padding: 7px 10px;
  border: 1px solid #ddd;
  font-size: 13px !important;
}
.add_card_section {
  background-color: #7e57c2;
  border: solid 1px rgba(77, 73, 112, 0.12);
  min-height: 240px;
  padding: 6px 15px;
  margin-top: 23px;
}
.add_bttn {
  width: 125px;
  height: 40px;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  text-align: center;
  color: #b13a5e !important;
}
.add_card_section .add_bttn {
  text-transform: capitalize;
  margin-left: 0;
}
.add_card_heading {
  font-size: 21px !important;
  font-weight: bold !important;
  line-height: 1.52 !important;
  color: #ffffff !important;
}
.add_card_sub_heading {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.87);
}
.card_heading {
  color: #333333;
  font-size: 29px;
  font-weight: bold;
}
.card_sub_heading {
  font-size: 16px;
  font-weight: normal;
  color: #333333;
}
.side_heading {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.02;
  color: #4a4a4a;
  margin-bottom: 36px;
  margin-top: 48px;
}
.card-bottom a,
.bullet-point a {
  color: #b13a5e !important;
}
.card-bottom b,
.card-bottom strong {
  font-weight: 500;
}
.category-filter-box .category-filter-item:nth-of-type(1n + 6),
.category-filter-box .less-link,
.category-filter-box.open .more-link {
  display: none;
}
.category-filter-box.open .category-filter-item:nth-of-type(1n + 6),
.category-filter-box.open .less-link,
.category-filter-box .more-link {
  display: block;
}
.modal.fade.in {
  background-color: rgba(78, 74, 112, 0.82) !important;
  z-index: 1111111;
}
#update-booking-detail .mat-form-field-underline {
  display: none;
}
#update-booking-detail .mat-form-field-wrapper {
  padding-bottom: 6px;
}
#update-booking-detail .help-block {
  margin-top: 0px;
}
#update-booking-detail .form-control {
  margin-bottom: 6px;
}

.booking-bottom {
  margin-top: 65px;
  background-color: #fff;
}
.booking-bottom .container {
  padding: 40px 15px 25px;
}
.booking-bottom h3 {
  margin-bottom: 20px;
  font-weight: 500;
}
.booking-bottom .normal {
  color: #333 !important;
}
.booking-bottom p {
  color: rgba(51, 51, 51, 0.54);
  line-height: 1.78;
  font-size: 15px;
  font-weight: 500;
}
.booking-bottom .lnk {
  color: #0076bd !important;
  text-decoration: underline;
}
[class^="mat-"],
[class*=" mat-"],
.mat-expansion-panel-content,
.mat-form-field,
.mat-select {
  /*  font-family: 'Libre Franklin', sans-serif !important; */
  font-family: "Roboto", sans-serif !important;
  font: inherit;
  font-size: 14px;
}
.mat-form-field-infix {
  width: 100% !important;
}
.mat-expansion-panel-spacing {
  margin: 0 !important;
}
.traveller .mat-form-field-underline {
  background-color: #ddd !important;
}
.mat-form-field-label {
  font-size: 13px !important;
}
.traveller .mat-select-placeholder {
  font-size: 13px;
  font-weight: 500;
}
/* Date picker*/
.DateInput__input {
  height: 100% !important;
}
.DateInput {
  font-size: 14px !important;
  width: 106px !important;
  padding-bottom: 0 !important;
}
.DateInput:first-child {
  padding-left: 0 !important;
}
.DateInput__display-text {
  font-weight: 400;
}
.DateInput:first-child .DateInput__display-text {
  padding-left: 0 !important;
  text-align: left;
}
.DateInput:first-child .DateInput__display-text--focused {
  padding-left: 5px !important;
  text-align: center;
}
.DateRangePickerInput__clear-dates svg,
.SingleDatePickerInput__clear-dates svg {
  height: 10px !important;
  width: 10px !important;
}
.DateRangePickerInput__clear-dates,
.SingleDatePickerInput__clear-dates {
  line-height: 10px !important;
  padding: 0 10px !important;
  margin: 15px 0 0 !important;
  float: right;
  min-width: inherit;
}
.DateRangePickerInput__clear-dates:focus,
.DateRangePickerInput__clear-dates--hover {
  background-color: unset;
}
.DateRangePickerInput,
.DateRangePicker,
.SingleDatePickerInput {
  width: 100%;
}
.DateInput__display-text {
  text-align: center;
}
.DateRangePickerInput,
.SingleDatePicker {
  border: 0 !important;
  border-bottom: 1px solid #999 !important;
  margin-top: 3px !important;
}
.hotel-detail-fixed-box .DateRangePickerInput {
  border: 1px solid #ccc !important;
}
.DateRangePickerInput__arrow,
.SingleDatePickerInput__arrow {
  margin-top: 8px;
}
.hotel-form .DateRangePickerInput,
.DateRangePicker {
  float: left;
}
.SingleDatePickerInput {
  border: none !important;
}

.SingleDatePicker {
  display: block !important;
}
.DateRangePickerInput__arrow svg,
.SingleDatePickerInput__arrow svg {
  height: 16px !important;
  width: 16px !important;
}
.hotel-form .DateRangePickerInput,
.hotel-form .SingleDatePickerInput {
  border: 1px solid #ccc !important;
  border-radius: 3px;
  margin: 0 !important;
}
.hotel-form .DateRangePickerInput .DateInput,
.hotel-form .SingleDatePickerInput .DateInput {
  padding: 4px !important;
}
.hotel-form .DateRangePickerInput__arrow,
.hotel-form .SingleDatePickerInput__arrow {
  margin-top: 0px;
}
.hotel-form .DateInput:first-child {
  padding-left: 10px !important;
}

/*   */
mat-form-field input[type="text"] {
  border-bottom: 0;
  box-shadow: unset;
}
.mat-expansion-indicator {
  transform: rotate(0deg);
  top: -16px;
  position: relative;
}
.mat-expansion-panel-body {
  padding: 0 !important;
}
.dropdown-menu {
  width: 340px;
  padding: 23px !important;
}
.dropdown-menu > .left {
  padding: 0 7px 0 0;
  border-right: 1px solid #ccc;
  width: 45%;
}
.dropdown-menu > .right {
  padding: 0 0 0 20px;
  width: 55%;
}
.dropdown-menu .lbl {
  font-weight: 500;
  height: 30px;
}
.dropdown-menu .lbl span {
  color: rgba(51, 51, 51, 0.54);
  font-size: 11px;
  padding-left: 2px;
  font-weight: 600;
  position: relative;
  top: -1px;
}
.dropdown-menu .add-sub {
  background-color: #fff;
  border: 1px solid #bbb;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-weight: 500;
  color: #888;
  font-size: 18px;
  padding: 0;
  line-height: 25px;
  cursor: pointer;
}
.dropdown-menu .dnum {
  width: 50px;
  line-height: 25px;
  text-align: center;
}
.add-sub span {
  position: relative;
  top: -3px;
}
.class-btn .btn {
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #ddd;
  width: 133px;
  color: #777 !important;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 500;
  padding: 0;
  height: 36px;
  margin-top: 15px;
  margin-bottom: 0;
}
.done-btn {
  float: right;
  margin-top: 12px;
  padding: 2px;
  cursor: pointer;
}

.fl-search-btn {
  font-size: 14px !important;
  font-weight: bold !important;
  line-height: 1.71 !important;
  background-color: #4611a7;
  box-shadow: unset !important;
  margin: 5px 0 0 !important;
  width: 100%;
  border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
}

/* hotels common code */
/*  */
.hotel-se-inp {
  border: 1px solid #ccc !important;
  border-radius: 3px !important;
  padding: 0 10px !important;
  height: 42px !important;
  font-size: 14px !important;
  box-sizing: border-box !important;
  margin: 0 !important;
  background-color: #fff !important;
}
.hotel-se-inp:focus {
  border-bottom: 1px solid #ccc !important;
  box-shadow: unset !important;
}
#guest-num {
  border: 1px solid #ccc;
  background-color: #fff;
  height: 42px;
  width: 100%;
  color: #757575;
  text-align: left;
  border-radius: 3px;
  padding: 0 10px;
  font-weight: 400;
}
/* guest dropdown */
.guest-dropdown .dropdown-menu {
  margin-top: 15px;
  margin-left: -11px;
}
/* search dropdown */
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.se-result-title {
  background-color: #eee;
  height: 35px;
  line-height: 35px;
  padding: 0 20px;
  color: #333;
}
.se-results ul {
  margin-bottom: 0;
}
.search-dropdown .dropdown-menu {
  padding: 0 !important;
}
.search-dropdown li {
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #eee;
  font-size: 13px;
}
.search-dropdown li a {
  color: #444 !important;
  display: block;
  padding: 0 20px;
}
.search-dropdown li a:hover,
.search-dropdown li a:focus,
.search-dropdown li a:active {
  background-color: #7e57c2;
  color: #fff !important;
}
/* search btn */
.hotel-form .fl-search-btn {
  margin-top: 0 !important;
  height: 42px;
  text-transform: capitalize;
  background-color: #b13a5e;
  font-weight: 500 !important;
  border: 1px solid #ccc;
  color: #fff;
}

.hotel-detail-mid .slider-nav .slick-next {
  right: -15px;
}
.hotel-detail-mid .slider-nav .slick-prev {
  left: -15px;
}
.fixed-box .DateInput:first-child {
  padding-left: 5px !important;
}
.fixed-box .DateInput {
  padding: 5px;
  font-size: 12px !important;
  width: 90px !important;
  padding-bottom: 5px !important;
}
.fixed-box .DateInput__display-text {
  padding: 3px !important;
  height: 28px;
}
.fixed-box .DateRangePickerInput__arrow {
  margin-top: -1px;
}
.fixed-box .DateRangePickerInput__clear-dates {
  padding: 4px !important;
  margin: 9px 3px 0 0 !important;
}
.fixed-box .DateRangePicker {
  margin-top: 10px;
}
.DateRangePickerInput {
  border-radius: 3px;
  overflow: hidden;
}
.DateRangePicker__picker {
  z-index: 2;
}
.SingleDatePicker {
  width: 100%;
}
.SingleDatePicker .DateInput {
  padding: 4px !important;
  width: 100% !important;
}
.SingleDatePicker .DateInput__display-text--focused {
  background: transparent;
}
.SingleDatePicker .DateInput:first-child .DateInput__display-text {
  padding-left: 32px !important;
  text-align: left;
}
.SingleDatePicker .DayPicker .transition-container {
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.profiledatepickbox .SingleDatePickerInput {
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
  box-sizing: border-box;
  height: 40px !important;
  padding-left: 10px;
  font-size: 14px;
  line-height: 1.5;
  color: #333333;
}
.profiledatepickbox .SingleDatePicker .DateInput {
  height: 38px;
}
.profiledatepickbox
  .SingleDatePicker
  .DateInput:first-child
  .DateInput__display-text {
  font-size: 14px;
  font-weight: 500;
  color: rgba(51, 51, 51, 0.54);
  padding-left: 0 !important;
}
/* hotel home slider */
.top-box .slider-bg,
.top-box .slider-bg .slick-list,
.slider-bg .slick-track {
  height: 100%;
}
.slick-track {
  margin-left: 0;
}
.slider-bg .slick-slide img {
  height: auto;
  min-height: 100%;
}

/* Headout details page */
.hotel-detail-mid table {
  width: 100%;
  border: 1px solid #dee2e6;
}
.hotel-detail-mid .tabbable-panel table td,
.hotel-detail-mid .tabbable-panel table th {
  padding: 0.75rem;
  vertical-align: top;
}
.hotel-detail-mid .tabbable-panel table tr:first-child td,
.hotel-detail-mid .tabbable-panel table th {
  border-bottom: 1px solid #dee2e6;
}
/* End */
/* Material modal changes */
.mat-dialog-content {
  padding: 0px 50px 24px;
  max-width: 620px;
  width: auto;
  margin-top: auto;
  height: auto;
  max-height: 85vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mat-dialog-content::-webkit-scrollbar {
  display: none;
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 8px !important;
  overflow: inherit !important;
}
.mat-dialog-content.modal-bg,
.mat-dialog-actions {
  padding: 30px 60px 24px !important;
  margin: 0 !important;
  box-shadow: none;
}
.mat-dialog-content .alert-danger {
  background-color: transparent;
  border: 0;
  padding: 2px 1px 2px;
  margin: 0;
  font-weight: 500;
  font-size: 11px;
  color: #bf0012;
}
.form-single-container {
  height: 80px;
  position: relative;
}
.form-single-desc-container {
  height: 100px;
}
.mat-dialog-content .inp-label {
  padding-bottom: 3px;
  color: rgba(51, 51, 51, 0.84);
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 0;
}
.mat-dialog-content .terms-row p {
  font-size: 12px;
}
.mat-dialog-content .sub-btn {
  margin: 10px 0 0;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500 !important;
}
.dialog-modal-logo {
  width: 40px;
  padding-left: 10px;
  margin-top: 2px;
}
.dialog-modal-title {
  width: calc(100% - 40px);
}
/* turf modal */
.enq-datepicker .mat-datepicker-toggle svg {
  width: 18px;
  position: relative;
  right: -6px;
  top: -2px;
}
.enq-datepicker .mat-datepicker-toggle svg > path:last-child {
  fill: #444;
}

.mat-autocomplete-panel {
  position: absolute !important;
  min-width: 390px !important;
}

.mat-option-text {
  display: inline-block;
  flex-grow: 1;
  overflow: hidden;
  color: #164880 !important;
  text-overflow: ellipsis;
}

.mat-option:focus:not(.mat-option-disabled),
.mat-option:hover:not(.mat-option-disabled) {
  background: rgba(51, 146, 225, 0.12) !important;
}

input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search-md"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
textarea.md-textarea {
  font-size: 14px !important;
}
mat-form-field input[type="text"] {
  border-bottom: 0;
  box-shadow: unset;
  height: 0 !important;
}

mat-form-field input[type="text"]:focus:not([readonly]) {
  border: none !important;
  box-shadow: none !important;
}

.mat-option-disabled .mat-option-text {
  color: rgba(0, 0, 0, 0.38) !important;
}

.loader {
  width: 100%;
  position: fixed;
  background: #000000c4;
  z-index: 111111;
  top: 0;
  bottom: 0;
  text-align: center;
  left: 0;
  right: 0;
}

.user-check-loader {
  border: 1px solid #f3f3f3;
  border-radius: 50%;
  border-top: 1px solid #0133ca;
  width: 17px;
  height: 17px;
  -webkit-animation: spin 2s linear infinite;
  opacity: 0.85;
  animation: spin 2s linear infinite;
}

.open-div {
  display: block !important;
}

.search-hotel-loader {
  font-size: 18px;
  float: right;
  margin-top: 3px;
}

.DateInput__input .needsclick {
  height: 2em !important;
}

.close-guest {
  float: right;
  clear: both;
  margin-top: 10px;
  cursor: pointer;
}
.mat-tooltip {
  font-size: 12px !important;
}
.gradient-btn {
  background: #ea1864;
  background: -moz-linear-gradient(left, #ea1864 0%, #ae275f 100%);
  background: -webkit-linear-gradient(left, #ea1864 0%, #ae275f 100%);
  background: linear-gradient(to right, #ea1864 0%, #ae275f 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ea1864',endColorstr='#ae275f',GradientType=1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  color: #fff;
  font-size: 14px;
  outline: none;
  border: 0;
}

.gradient-btn:hover,
.gradient-btn:focus {
  background: #ae275f;
  background: -moz-linear-gradient(left, #ae275f 0%, #ea1864 100%);
  background: -webkit-linear-gradient(left, #ae275f 0%, #ea1864 100%);
  background: linear-gradient(to right, #ae275f 0%, #ea1864 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ae275f',endColorstr='#ea1864',GradientType=1);
}
.sticky .navbar-dropdown-link #card-menu-button,
.default-head .navbar-dropdown-link #card-menu-button,
.sticky #cat-menu-button,
.default-head #cat-menu-button {
  color: #333 !important;
}
.profile-main-wrapper {
  margin-top: 110px;
  min-height: calc(100vh - 231px);
}
.form-control:disabled,
.form-control[readonly] {
  cursor: not-allowed;
}
.mat-datepicker-toggle .mat-icon-button {
  outline: 0;
}
.sticky .profile-logo svg path,
.default-head .profile-logo svg path {
  fill: #ae275f;
}
.sticky .profile-down-arr,
.default-head .profile-down-arr {
  color: #ae275f;
}
.visible-mob {
  display: none;
}
.default-head .mobile-menu-ic .lin {
  background-color: #888 !important;
}
.enq-cric .mat-dialog-actions {
  padding-top: 15px !important;
}
#search-autosuggest {
  width: 707px;
  top: 100%;
}
#search-autosuggest {
  display: none;
  position: absolute;
  background-color: #fff;
  top: 99%;
  left: 0;
  z-index: 2;
  border: 1px solid #dcdcdc;
  border-radius: 0 0 0.25rem 0.25rem;
  -moz-border-radius: 0 0 0.25rem 0.25rem;
  -webkit-border-radius: 0 0 0.25rem 0.25rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.vanilla_ul ul,
.what_included_data ul {
  list-style-type: disc;
  padding: 0 20px;
  margin: 10px;
}

select {
  box-shadow: none !important;
}
@media only screen and (min-width: 0em) and (max-width: 47.9375em) {
  #search-autosuggest {
    max-height: 27vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
  }
}

@media only screen and (min-width: 48em) {
  #search-autosuggest {
    box-sizing: content-box;
    left: -1px;
  }
}

body:not(.touchscreen) #search-autosuggest span:hover {
  background-color: #b0255d;
}
body:not(.touchscreen) #search-autosuggest span:hover a {
  color: #fff;
}

#search-autosuggest span {
  display: inline-block;
  margin: 0 0.375rem 0.5rem 0;
  min-height: 1.5rem;
  border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  -webkit-border-radius: 0.1875rem;
  text-align: center;
  cursor: pointer;
  max-height: none;
  box-sizing: border-box;
  font-size: 0.875rem;
  color: #b0255d;
  background-color: #fff;
  border: 1px solid #b0255d;
}
#search-autosuggest span a {
  color: #b0255d !important;
}
#search-autosuggest span a:hover {
  color: #fff !important;
}
#search-autosuggest span a,
#search-autosuggest span a:visited {
  padding: 0.375rem 0.75rem;
  display: block;
}

#search-autosuggest table {
  border-spacing: 0;
  width: 100%;
}

@media only screen and (min-width: 0em) and (max-width: 47.9375em) {
  #search-autosuggest table tr td {
    border-bottom: 1px solid #dcdcdc;
    padding: 0.5rem 0.25rem 0.25rem;
  }
  #search-autosuggest table tr td:first-child {
    display: none;
  }
}

@media only screen and (min-width: 48em) {
  #search-autosuggest table tr td {
    padding-left: 1rem;
    padding-top: 5px !important;
  }
  #search-autosuggest table tr td:first-child {
    text-transform: uppercase;
    background-color: #242e36;
    color: #fff;
    font-weight: 300;
    letter-spacing: 0.06rem;
    font-size: 11px !important;
  }
}

@media only screen and (min-width: 48em) and (min-width: 0em) and (max-width: 47.9375em) {
  #search-autosuggest table tr td:first-child {
    padding: 0 0.2rem;
    width: 8%;
    font-size: 0.7rem;
    vertical-align: top;
  }
}

@media only screen and (min-width: 48em) and (min-width: 48em) {
  #search-autosuggest table tr td:first-child {
    padding: 0.75rem 1.5rem;
    width: 17%;
    font-size: 0.75rem;
  }
}

@media only screen and (min-width: 64em) {
  #search-autosuggest table tr:first-child td:last-child {
    padding-top: 0;
  }
}

@media only screen and (min-width: 64em) {
  #search-autosuggest table tr.category-collections td:first-child {
    padding-top: 0.3rem;
  }
}

#search-autosuggest td.no-results {
  background-color: #fff !important;
  color: #2b2b2b !important;
  font-weight: 400 !important;
}

#search-button {
  border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  -webkit-border-radius: 1.5rem;
  position: relative;
  left: 0.5rem;
  margin: 0;
  cursor: pointer;
  display: none;
  background: #2b3540;
  width: 2.25rem;
  height: 2.25rem;
}

@media only screen and (min-width: 48em) {
  #search-button {
    left: 0.875rem;
    width: 2rem;
    height: 2rem;
  }
}

#search-button span {
  background-image: url("/bundles/familysystem/images/marvin-sprites_v18.svg");
  background-repeat: no-repeat;
  background-position: -148.5rem -9rem;
  background-size: 536.25rem 32.5rem;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  vertical-align: middle;
  left: -1px;
  top: 5px;
  position: relative;
}

@media only screen and (min-width: 48em) {
  #search-button span {
    left: 0;
    top: 2px;
  }
}

@media only screen and (min-width: 64em) {
  #search-button {
    display: none;
  }
}

@media only screen and (min-width: 48em) {
  #search-dismiss {
    background-image: url("/bundles/familysystem/images/marvin-sprites_v18.svg");
    background-repeat: no-repeat;
    background-position: -90rem -9rem;
    background-size: 536.25rem 32.5rem;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    cursor: pointer;
    font-size: 0;
    margin: 0;
  }
}

@media only screen and (min-width: 64em) {
  #search-dismiss {
    display: none;
  }
}

#autosuggest-container {
  box-sizing: border-box;
  height: 100%;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  position: absolute;
  top: 0;
  text-align: left;
  width: 100%;
}

#autosuggest-container::before {
  content: " ";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
  width: 0;
}

#autosuggest-container > * {
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  -o-user-select: text;
}
/* for headout */
.headout-tabs h2,
.headout-tabs h3,
.headout-tabs h4 {
  margin-bottom: 15px;
  width: 100%;
}
.headout-tabs h2 {
  font-size: 20px;
}
.headout-tabs h3 {
  font-size: 16px;
}
.headout-tabs h4 {
  font-size: 15px;
}
.headout-tabs .ntk-info ul,
.headout-tabs .ntk-info ol {
  list-style-type: disc;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
}
.headout-tabs .ntk-info li {
  padding-bottom: 8px;
  width: 100%;
}
.headout-tabs .ntk-info p {
  width: 100%;
}
.ntk-info img {
  max-width: 100% !important;
  border-radius: 5px;
}
.ntk-info div {
  max-width: 100%;
}
/* for headout ends*/

/* golf started */
.mat-button .mat-button-focus-overlay,
.mat-icon-button .mat-button-focus-overlay,
.mat-button-ripple-round {
  display: none;
}
.mat-datepicker-toggle {
  height: 34px;
  position: absolute;
  right: 15px;
  top: 18px;
  width: calc(100% - 30px);
  text-align: right;
}
.mat-datepicker-toggle .mat-icon-button {
  width: 100%;
  height: 34px;
  text-align: right;
}
.mat-datepicker-toggle .mat-icon-button .mat-button-wrapper {
  margin-right: 10px;
}
.enq-datepicker .mat-datepicker-toggle svg > path:last-child {
  fill: #777;
}
.slider-bg .slick-slide img {
  object-fit: cover;
}
.tab-content .card {
  border-radius: 0;
  margin-bottom: 20px;
}
.tab-content .btn-link {
  text-decoration: none;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  text-transform: uppercase;
}
.tab-content ul,
.tab-content ol {
  list-style-type: disc;
  padding-left: 15px;
  padding-top: 5px !important;
  padding-bottom: 20px;
}
.tab-content li {
  margin-bottom: 10px;
}
.itineryAccbodyRT {
  position: relative;
  width: 35%;
  padding: 0 0 0 10px;
  margin: 0;
  display: inline-block;
}
.itineryAccbodyRT img {
  position: relative;
  width: 100%;
  float: right;
  padding: 0;
  margin: 0;
  display: inline-block;
  max-width: 350px;
}
.itineryAccbodyLT {
  position: relative;
  width: 65%;
  float: left;
  padding: 0 10px 0 0;
  margin: 0;
  display: inline-block;
}
.tab-content .card-body {
  padding: 30px 20px 25px;
  float: left;
  width: 100%;
}
.tab-content .card-header {
  background-color: #b13a5e0a;
}
.theme-green .bs-datepicker-head {
  background-color: #b13a5e;
}
.invalid-feedback {
  display: block;
}
/* golf end */
@media only screen and (min-width: 0em) and (max-width: 47.9375em) {
  #autosuggest-container {
    padding-left: 3rem;
  }
}

@media only screen and (min-width: 48em) {
  #autosuggest-container {
    padding-left: 4.0625rem;
  }
}

body:not(.touchscreen)
  #autosuggest-container:not(.hideSuggestions):hover
  #search-autosuggest.hasContent {
  display: block;
}

body:not(.touchscreen)
  #autosuggest-container:not(.hideSuggestions)
  #react-s_query:focus
  + #search-autosuggest {
  display: block;
}

.open-div {
  display: block !important;
}
.headout-checkout-more-date .SingleDatePicker__picker {
  left: inherit !important;
  right: 0;
}
@media screen and (max-width: 1200px) {
  .DateRangePickerInput__clear-dates {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .container {
    width: auto !important;
  }
  .no-pad-rht-sm {
    padding-right: 0 !important;
  }
  .hotel-detail-fixed-box .DateRangePicker__picker {
    left: 0px !important;
  }
}
@media screen and (max-width: 992px) {
  .hidden-992 {
    display: none;
  }
  .headout-popular.cards-sec .slick-prev,
  .headout-popular.cards-sec .slick-next {
    top: -37px !important;
  }
  .headout-popular.cards-sec .slick-prev {
    top: 93px !important;
    left: -12px;
    z-index: 1;
  }
  .headout-popular.cards-sec .slick-next {
    top: 93px !important;
    right: -7px;
    z-index: 1;
  }
}
@media screen and (max-width: 768px) {
  .top-bg .slick-slide {
    height: 100vh;
    min-height: 1180px;
  }

  .modal-bg {
    width: auto !important;
    padding: 0px 20px 24px !important;
  }
  .sign-up-form .form-control {
    width: 100% !important;
    box-sizing: border-box !important;
    height: 36px !important;
  }
  .col-right-xs {
    float: right !important;
  }
  .no-padding-xs {
    padding: 0 !important;
  }
  .visible-xs {
    display: block !important;
  }
  .hidden-xs {
    display: none !important;
  }
  .xs-pad-top-15 {
    padding-top: 15px;
  }
  .xs-100 {
    width: 100% !important;
  }
  .padding-xs {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .DateRangePicker .DayPicker__week-header:nth-child(2) {
    top: 314px !important;
    left: 0 !important;
  }
  .DateRangePicker__picker,
  .CalendarMonthGrid {
    width: 295px !important;
  }
  .DayPicker--horizontal {
    max-width: 100% !important;
  }
  .transition-container {
    height: 540px !important;
    width: 295px !important;
  }
  .sticky .color-img,
  .default-head .color-img {
    display: none !important;
  }

  .sticky .default-img,
  .default-head .default-img,
  .default-img {
    display: none !important;
  }
  .sticky:not .default-img-mob,
  .default-head:not .default-img-mob,
  .default-img-mob {
    display: inline-block !important;
  }
  .default-img-mob {
    display: inline-block !important;
  }

  .default-head .default-img-mob {
    display: none !important;
  }

  .sticky .default-img-mob {
    display: none !important;
  }
  .sticky .color-img-mob,
  .default-head .color-img-mob {
    display: inline-block !important;
  }
  .color-img-mob,
  .default-img-mob {
    width: 35px;
  }
  .mob-width-12 {
    width: 100%;
  }
  .mob-width-12 {
    width: 100% !important;
  }
  .show-tab {
    display: block !important;
  }
  .hide-tab {
    display: none !important;
  }
}
@media (max-width: 576px) {
  .tab-content .btn-link {
    font-size: 12px;
    padding: 0;
  }
  .mob-xs-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mob-block {
    display: block;
  }
  .mat-dialog-content.modal-bg,
  .mat-dialog-actions {
    padding: 30px 30px 24px !important;
  }
  .mob-width-12 {
    width: 100% !important;
  }
  .hidden-mob {
    display: none;
  }
  .visible-mob {
    display: block;
  }

  .show-mob {
    display: block !important;
  }
  .hide-mob {
    display: none !important;
  }

  .headout-popular {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.success {
  color: green;
}

ng-otp-input .wrapper {
  display: flex !important;
}
ng-otp-input .otp-input {
  border: 1px solid gray !important;
  font-size: 14px !important;
}
ng-otp-input .otp-input:focus {
  outline: none;
}
@media (min-width: 576px) {
  ng-otp-input .otp-input {
    width: 35px !important;
  }
}

.toast-container .ngx-toastr {
  width: 450px !important;
  @media (max-width: 576px) {
    width: 90vw;
  }
  width: 350px !important;
}

.carousel-control-prev {
  left: auto;
  right: 48px;
  top: -55px;
  height: 30px;
  background-color: #71717178;
  border-radius: 50%;
  width: 30px !important;
}
.carousel-control-prev-icon {
  background-image: url("https://apufpel.com.br/assets/img/seta_prim.png");
  padding: 10px;
  width: 20px;
  height: 20px;
}
.carousel-control-next {
  left: auto;
  right: 12px;
  top: -55px;
  height: 30px;
  background-color: #71717178;
  border-radius: 50%;
  width: 30px !important;
}
.carousel-control-next-icon {
  background-image: url("https://apufpel.com.br/assets/img/seta_ult.png");
  padding: 10px;
  width: 20px;
  height: 20px;
}
.carousel-item {
  display: none;
}

.carousel-item.active {
  display: flex;
  justify-content: space-between;
  a {
    margin: auto;
  }
}

.carousel {
  .visually-hidden {
    display: none;
  }
}
mwl-confirmation-popover-window {
  .popover-header {
    border-bottom: 1px solid #f2f2f2;
  }

  @media (max-width: 768px) {
    left: auto;
    right: auto;
  }

  .popover {
    background-color: #fff !important;
    max-width: 186px !important;

    .btn-outline-secondary,
    .btn-danger {
      width: -webkit-fill-available;
    }

    .btn-danger {
      color: #fff;
    }
  }
}
