// Generate background color and color classes
$mainColor: #3a3660;
:root {
  --linkBlue: #2F80ED;
}
.link-blue{
  color: var(--linkBlue);
  &:hover{
    color: var(--linkBlue);
  }
}
a{
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
}
*{
  -webkit-font-smoothing: auto;
}
img{
    image-rendering: -webkit-optimize-contrast;
}
.d-none{
  display: none;
}
.modal-backdrop{
      z-index: 1;
}
.modal-backdrop {
    background: rgba(0, 0, 0, 0.4) !important;
}
.block{
  display: block;
}
.wrap{
  flex-wrap: wrap;
}
.btn {
  box-shadow: unset !important;
}
body{
  font-size: 14px;
  background-color: #fff;
  color: #0E202E;
  font-family: 'Avenir'
}
.dropdown-menu{
  font-size: 14px;
  border: 0;
}
.desc-box{
  .bs-tooltip-top{
    font-family: inherit;
    .tooltip-inner{
      max-width: 100%;
      padding: 10px 20px;
      border-radius: 6px;
      background-color: #131313;
    }
  }
}
button, input, optgroup, select, textarea{
  font-family: inherit;
}
.drag-scroll-content::-webkit-scrollbar, .slide-menu-content::-webkit-scrollbar,.couponmodal::-webkit-scrollbar,
.generatemodal::-webkit-scrollbar, .template-box::-webkit-scrollbar, .custom-scroller::-webkit-scrollbar,.slideshow-container::-webkit-scrollbar {
    display: none;
}
.slide-menu-content, .couponmodal, .generatemodal, .custom-scroller, .slideshow-container{
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}
.page-loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #0133CA;
  width: 35px;
  height: 35px;
  -webkit-animation: spin 1s linear infinite;
  opacity: .85;
  animation: spin 1s linear infinite;
}

.small-loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #0133CA;
  width: 19px;
  height: 19px;
  -webkit-animation: smallspin 2s linear infinite;
  opacity: .85;
  animation: smallspin 2s linear infinite;
}
.small-btn-loader {
  width: 16px;
  height: 16px;
  right: 8px;
  top: 11px;
}
.selected-country-flag{
  border: 1px solid #eee;
}
/* Safari */
@-webkit-keyframes smallspin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes smallspin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.curved-btn{
padding: 0 25px;
height: 36px;
margin: 0 10px;
border-radius: 24px;
text-transform: uppercase;
font-size: 12px;
font-weight: 600;
}
.shadow-btn{
      box-shadow: 0px 2px 4px -1px #bfbfbf;
}
.curved-btn a{
      color: #fff;
}
.c-555{
  color: #555;
}
.c-777{
  color: #777;
}
.c-999{
  color: #999;
}
.bg-fff{
  background-color: #fff;
}
strong{
  font-weight: 600;
}
// .animated {
//    -webkit-animation-duration: 1s;
//    animation-duration: 1s;
//    -webkit-animation-fill-mode: both;
//    animation-fill-mode: both;
// }
// @-webkit-keyframes fadeIn {
//   0% {opacity: 0;}
//   100% {opacity: 1;}
// }
// @keyframes fadeIn {
//   0% {opacity: 0;}
//   100% {opacity: 1;}
// }
// .fadeIn {
//   -webkit-animation-name: fadeIn;
//   animation-name: fadeIn;
// }

carousel.slide-animated {
  slide.item {
    animation-duration: 1s;
    animation-fill-mode: both;
    &.active {
      width: 100%;
      animation-name: carouselSlideIn;
    }
    &:not(.active) {
      display: table-cell;
      animation-name: carouselSlideOut;
    }
  }
}

@keyframes carouselSlideIn {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0); }
}
@keyframes carouselSlideOut {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}
.gift-slider-contain{
  .carousel-control{
    display: none;
  }
  .carousel-inner{
    width: calc(100% + 30px);
    overflow: hidden;
    margin: -15px;
    padding: 15px;
  }
  .carousel-indicators{
    bottom: 32px;
  }
  .single-slide{
    .item{
      padding-bottom: 20px;
      border-radius: 4px;
    }
    &.active{
      .item{
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
        // opacity: 1;
      }
    }
  }
}

// .sidebar .ng-scroll-view-container{
//   border-right: 1px solid #e2e2e2;
//   margin-top: 30px !important;
// }
// View More Link
.view-more {
  display: block;
  text-transform: uppercase;
  padding: 1.1rem 0;
  text-align: center;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  // @include transition(color 300ms);
}
.inline-block{
      display: inline-block;
}
.z1{
  z-index: 1;
}
.w100{
  width: 100%;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.flex{
  display: flex;
}
.uppercase{
  text-transform: uppercase;
}
.capitalize{
  text-transform: capitalize;
}
.cursor-default{
  cursor: default !important;
}
.popover-body{
  padding: 4px 15px 6px;
border-radius: 8px;
font-size: 12px;
}
.object-fit{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hide{
  display: none;
}
.show{
  display: block !important;
}
.pointer{
  cursor: pointer;
}
.underline{
  text-decoration: underline;
}
.inline-block{
  display: inline-block;
}
.justify-center{
      justify-content: center;
}
.justify-end{
      justify-content: flex-end;
}
.space-between{
  justify-content: space-between
}
.align-center{
      align-items: center;
}
.align-baseline{
      align-items: baseline;
}
.page-link{
  margin: 0 4px !important;
}
.green{
  color: #00a975
}
.left{
  float: left;
}
.f300{
  font-weight: 300;
}
.f400{
  font-weight: 400;
}
.f500{
  font-weight: 500;
}
.f600{
  font-weight: 600;
}
.fnt-9{
  font-size: 9px;
}
.fnt-10{
  font-size: 10px;
}
.fnt-11{
  font-size: 11px;
}
.fnt-12{
  font-size: 12px;
}
.fnt-13{
  font-size: 13px;
}
.fnt-14{
  font-size: 14px;
}
.fnt-15{
  font-size: 15px;
}
.fnt-16{
  font-size: 16px;
}
.fnt-17{
  font-size: 17px;
}
.fnt-18{
  font-size: 18px;
}
.fnt-19{
  font-size: 19px;
}
.fnt-20{
  font-size: 20px;
}
.fnt-21{
  font-size: 21px;
}
.fnt-22{
  font-size: 22px;
}
.fnt-23{
  font-size: 23px;
}
.fnt-24{
  font-size: 24px;
}
.fnt-25{
  font-size: 25px;
}
.fnt-26{
  font-size: 26px;
}
.fnt-27{
  font-size: 27px;
}
.fnt-28{
  font-size: 28px;
}
.fnt-29{
  font-size: 29px;
}
.fnt-30{
  font-size: 30px;
}
.fnt-31{
  font-size: 31px;
}
.fnt-32{
  font-size: 32px;
}
.fnt-33{
  font-size: 33px;
}
.fnt-34{
  font-size: 34px;
}
.fnt-35{
  font-size: 35px;
}
.fnt-36{
  font-size: 36px;
}
.fnt-37{
  font-size: 37px;
}
.fnt-38{
  font-size: 38px;
}
.fnt-39{
  font-size: 39px;
}
.fnt-40{
  font-size: 40px;
}
.h100{
  height: 100%;
}
.mar-15{
  margin: 15px;
}
.mar-30{
  margin: 30px;
}
.mar-top-1{
    margin-top: 1px;
}
.mar-top-2{
    margin-top: 2px;
}
.mar-top-3{
    margin-top: 3px;
}
.mar-top-4{
    margin-top: 4px;
}
.mar-top-5{
    margin-top: 5px;
}
.mar-top-6{
    margin-top: 6px;
}
.mar-top-7{
    margin-top: 7px;
}
.mar-top-8{
    margin-top: 8px;
}
.mar-top-9{
    margin-top: 9px;
}
.mar-top-10{
    margin-top: 10px;
}
.mar-top-11{
    margin-top: 11px;
}
.mar-top-12{
    margin-top: 12px;
}
.mar-top-13{
    margin-top: 13px;
}
.mar-top-14{
    margin-top: 14px;
}
.mar-top-15{
    margin-top: 15px;
}
.mar-top-16{
    margin-top: 16px;
}
.mar-top-17{
    margin-top: 17px;
}
.mar-top-18{
    margin-top: 18px;
}
.mar-top-19{
    margin-top: 19px;
}
.mar-top-20{
    margin-top: 20px;
}
.mar-top-21{
    margin-top: 21px;
}
.mar-top-22{
    margin-top: 22px;
}
.mar-top-23{
    margin-top: 23px;
}
.mar-top-24{
    margin-top: 24px;
}
.mar-top-25{
    margin-top: 25px;
}
.mar-top-26{
    margin-top: 26px;
}
.mar-top-27{
    margin-top: 27px;
}
.mar-top-28{
    margin-top: 28px;
}
.mar-top-29{
    margin-top: 29px;
}
.mar-top-30{
    margin-top: 30px;
}
.mar-top-31{
    margin-top: 31px;
}
.mar-top-32{
    margin-top: 32px;
}
.mar-top-33{
    margin-top: 33px;
}
.mar-top-34{
    margin-top: 34px;
}
.mar-top-35{
    margin-top: 35px;
}
.mar-top-36{
    margin-top: 36px;
}
.mar-top-37{
    margin-top: 37px;
}
.mar-top-38{
    margin-top: 38px;
}
.mar-top-39{
    margin-top: 39px;
}
.mar-top-40{
    margin-top: 40px;
}
.mar-top-41{
    margin-top: 41px;
}
.mar-top-42{
    margin-top: 42px;
}
.mar-top-43{
    margin-top: 43px;
}
.mar-top-44{
    margin-top: 44px;
}
.mar-top-45{
    margin-top: 45px;
}
.mar-top-46{
    margin-top: 46px;
}
.mar-top-47{
    margin-top: 47px;
}
.mar-top-48{
    margin-top: 48px;
}
.mar-top-49{
    margin-top: 49px;
}
.mar-top-45-imp{
    margin-top: 45px !important;
}
.mar-top-50{
    margin-top: 50px;
}
.mar-bot-0{
    margin-bottom: 0px;
}
.mar-bot-1{
    margin-bottom: 1px;
}
.mar-bot-2{
    margin-bottom: 2px;
}
.mar-bot-3{
    margin-bottom: 3px;
}
.mar-bot-4{
    margin-bottom: 4px;
}
.mar-bot-5{
      margin-bottom: 5px;
}
.mar-bot-6{
      margin-bottom: 6px;
}
.mar-bot-7{
      margin-bottom: 7px;
}
.mar-bot-8{
      margin-bottom: 8px;
}
.mar-bot-9{
      margin-bottom: 9px;
}
.mar-bot-10{
      margin-bottom: 10px;
}
.mar-bot-11{
      margin-bottom: 11px;
}
.mar-bot-12{
      margin-bottom: 12px;
}
.mar-bot-13{
      margin-bottom: 13px;
}
.mar-bot-14{
      margin-bottom: 14px;
}
.mar-bot-15{
      margin-bottom: 15px;
}
.mar-bot-16{
      margin-bottom: 16px;
}
.mar-bot-17{
      margin-bottom: 17px;
}
.mar-bot-18{
      margin-bottom: 18px;
}
.mar-bot-19{
      margin-bottom: 19px;
}
.mar-bot-20{
      margin-bottom: 20px;
}
.mar-bot-21{
      margin-bottom: 21px;
}
.mar-bot-22{
      margin-bottom: 22px;
}
.mar-bot-23{
      margin-bottom: 23px;
}
.mar-bot-24{
      margin-bottom: 24px;
}
.mar-bot-25{
      margin-bottom: 25px;
}
.mar-bot-26{
      margin-bottom: 26px;
}
.mar-bot-27{
      margin-bottom: 27px;
}
.mar-bot-28{
      margin-bottom: 28px;
}
.mar-bot-29{
      margin-bottom: 29px;
}
.mar-bot-30{
      margin-bottom: 30px;
}
.mar-bot-31{
      margin-bottom: 31px;
}
.mar-bot-32{
      margin-bottom: 32px;
}
.mar-bot-33{
      margin-bottom: 33px;
}
.mar-bot-34{
      margin-bottom: 34px;
}
.mar-bot-35{
      margin-bottom: 35px;
}
.mar-bot-36{
      margin-bottom: 36px;
}
.mar-bot-37{
      margin-bottom: 37px;
}
.mar-bot-38{
      margin-bottom: 38px;
}
.mar-bot-39{
      margin-bottom: 39px;
}
.mar-bot-40{
      margin-bottom: 40px;
}
.mar-bot-41{
      margin-bottom: 41px;
}
.mar-bot-42{
      margin-bottom: 42px;
}
.mar-bot-43{
      margin-bottom: 43px;
}
.mar-bot-44{
      margin-bottom: 44px;
}
.mar-bot-45{
      margin-bottom: 45px;
}
.mar-bot-46{
      margin-bottom: 46px;
}
.mar-bot-47{
      margin-bottom: 47px;
}
.mar-bot-48{
      margin-bottom: 48px;
}
.mar-bot-49{
      margin-bottom: 49px;
}
.mar-bot-50{
      margin-bottom: 50px;
}
.mar-bot-51{
      margin-bottom: 51px;
}
.mar-bot-52{
      margin-bottom: 52px;
}
.mar-bot-53{
      margin-bottom: 53px;
}
.mar-bot-54{
      margin-bottom: 54px;
}
.mar-bot-55{
      margin-bottom: 55px;
}
.mar-bot-56{
      margin-bottom: 56px;
}
.mar-bot-60{
      margin-bottom: 60px;
}
.mar-left-1{
    margin-left: 1px;
}
.mar-left-2{
    margin-left: 2px;
}
.mar-left-3{
    margin-left: 3px;
}
.mar-left-4{
    margin-left: 4px;
}
.mar-left-5{
    margin-left: 5px;
}
.mar-left-6{
    margin-left: 6px;
}
.mar-left-7{
    margin-left: 7px;
}
.mar-left-8{
    margin-left: 8px;
}
.mar-left-9{
    margin-left: 9px;
}
.mar-left-10{
    margin-left: 10px;
}
.mar-left-11{
    margin-left: 11px;
}
.mar-left-12{
    margin-left: 12px;
}
.mar-left-13{
    margin-left: 13px;
}
.mar-left-14{
    margin-left: 14px;
}
.mar-left-15{
    margin-left: 15px;
}
.mar-left-16{
    margin-left: 16px;
}
.mar-left-17{
    margin-left: 17px;
}
.mar-left-18{
    margin-left: 18px;
}
.mar-left-19{
    margin-left: 19px;
}
.mar-left-20{
    margin-left: 20px;
}
.mar-left-21{
    margin-left: 21px;
}
.mar-left-22{
    margin-left: 22px;
}
.mar-left-23{
    margin-left: 23px;
}
.mar-left-24{
    margin-left: 24px;
}
.mar-left-25{
    margin-left: 25px;
}
.mar-left-26{
    margin-left: 26px;
}
.mar-left-27{
    margin-left: 27px;
}
.mar-left-28{
    margin-left: 28px;
}
.mar-left-29{
    margin-left: 29px;
}
.mar-left-30{
    margin-left: 30px;
}
.mar-left-31{
    margin-left: 31px;
}
.mar-left-32{
    margin-left: 32px;
}
.mar-left-33{
    margin-left: 33px;
}
.mar-left-34{
    margin-left: 34px;
}
.mar-left-35{
    margin-left: 35px;
}
.mar-left-36{
    margin-left: 36px;
}
.mar-left-37{
    margin-left: 37px;
}
.mar-left-38{
    margin-left: 38px;
}
.mar-left-39{
    margin-left: 39px;
}
.mar-left-40{
    margin-left: 40px;
}
.mar-left-41{
    margin-left: 41px;
}
.mar-left-42{
    margin-left: 42px;
}
.mar-left-43{
    margin-left: 43px;
}
.mar-left-44{
    margin-left: 44px;
}
.mar-left-45{
    margin-left: 45px;
}
.mar-left-46{
    margin-left: 46px;
}
.mar-left-47{
    margin-left: 47px;
}
.mar-left-48{
    margin-left: 48px;
}
.mar-left-49{
    margin-left: 49px;
}
.mar-left-45-imp{
    margin-left: 45px !important;
}
.mar-left-50{
    margin-left: 50px;
}
.mar-right-1{
    margin-right: 1px;
}
.mar-right-2{
    margin-right: 2px;
}
.mar-right-3{
    margin-right: 3px;
}
.mar-right-4{
    margin-right: 4px;
}
.mar-right-5{
    margin-right: 5px;
}
.mar-right-6{
    margin-right: 6px;
}
.mar-right-7{
    margin-right: 7px;
}
.mar-right-8{
    margin-right: 8px;
}
.mar-right-9{
    margin-right: 9px;
}
.mar-right-10{
    margin-right: 10px;
}
.mar-right-11{
    margin-right: 11px;
}
.mar-right-12{
    margin-right: 12px;
}
.mar-right-13{
    margin-right: 13px;
}
.mar-right-14{
    margin-right: 14px;
}
.mar-right-15{
    margin-right: 15px;
}
.mar-right-16{
    margin-right: 16px;
}
.mar-right-17{
    margin-right: 17px;
}
.mar-right-18{
    margin-right: 18px;
}
.mar-right-19{
    margin-right: 19px;
}
.mar-right-20{
    margin-right: 20px;
}
.mar-right-21{
    margin-right: 21px;
}
.mar-right-22{
    margin-right: 22px;
}
.mar-right-23{
    margin-right: 23px;
}
.mar-right-24{
    margin-right: 24px;
}
.mar-right-25{
    margin-right: 25px;
}
.mar-right-26{
    margin-right: 26px;
}
.mar-right-27{
    margin-right: 27px;
}
.mar-right-28{
    margin-right: 28px;
}
.mar-right-29{
    margin-right: 29px;
}
.mar-right-30{
    margin-right: 30px;
}
.mar-right-31{
    margin-right: 31px;
}
.mar-right-32{
    margin-right: 32px;
}
.mar-right-33{
    margin-right: 33px;
}
.mar-right-34{
    margin-right: 34px;
}
.mar-right-35{
    margin-right: 35px;
}
.mar-right-36{
    margin-right: 36px;
}
.mar-right-37{
    margin-right: 37px;
}
.mar-right-38{
    margin-right: 38px;
}
.mar-right-39{
    margin-right: 39px;
}
.mar-right-40{
    margin-right: 40px;
}
.mar-right-41{
    margin-right: 41px;
}
.mar-right-42{
    margin-right: 42px;
}
.mar-right-43{
    margin-right: 43px;
}
.mar-right-44{
    margin-right: 44px;
}
.mar-right-45{
    margin-right: 45px;
}
.mar-right-46{
    margin-right: 46px;
}
.mar-right-47{
    margin-right: 47px;
}
.mar-right-48{
    margin-right: 48px;
}
.mar-right-49{
    margin-right: 49px;
}
.mar-right-45-imp{
    margin-right: 45px !important;
}
.mar-right-50{
    margin-left: 50px;
}
.mar-bot-25-imp{
      margin-bottom: 25px !important;
}
.margin-tb-16{
      margin: 16px 0 !important;
}
.no-margin{
  margin: 0 !important;
}
.no-margin-rht{
  margin-right: 0;
}
.no-margin-lft{
  margin-left: 0;
}
.no-margin{
  margin: 0 !important;
}
.no-margin-lr{
  margin-left:  0;
  margin-right: 0;
}
.no-padding-top{
  padding-top: 0;
}
.no-padding-bot{
    padding-top: 0;
}
.no-padding-left{
  padding-left: 0;
}
.no-padding-right{
  padding-right: 0;
}
.pad-5{
  padding: 5px;
}
.pad-30{
      padding: 30px;
}
.pad-lft-10{
  padding-left: 10px;
}
.pad-rht-10{
  padding-right: 10px;
}
.no-padding-lr{
  padding-left: 0;
  padding-right: 0;
}
.no-padding{
  padding: 0;
}
.no-padding-imp{
  padding: 0 !important;
}
.pad-0 {
  padding: 0px;
}
.pad-1 {
  padding: 1px;
}
.pad-2 {
  padding: 2px;
}
.pad-3 {
  padding: 3px;
}
.pad-4 {
  padding: 4px;
}
.pad-5 {
  padding: 5px;
}
.pad-6 {
  padding: 6px;
}
.pad-7 {
  padding: 7px;
}
.pad-8 {
  padding: 8px;
}
.pad-9 {
  padding: 9px;
}
.pad-10 {
  padding: 10px;
}
.pad-11 {
  padding: 11px;
}
.pad-12 {
  padding: 12px;
}
.pad-13 {
  padding: 13px;
}
.pad-14 {
  padding: 14px;
}
.pad-15 {
  padding: 15px;
}
.pad-16 {
  padding: 16px;
}
.pad-17 {
  padding: 17px;
}
.pad-18 {
  padding: 18px;
}
.pad-19 {
  padding: 19px;
}
.pad-20 {
  padding: 20px;
}
.pad-21 {
  padding: 21px;
}
.pad-22 {
  padding: 22px;
}
.pad-23 {
  padding: 23px;
}
.pad-24 {
  padding: 24px;
}
.pad-25 {
  padding: 25px;
}
.pad-26 {
  padding: 26px;
}
.pad-27 {
  padding: 27px;
}
.pad-28 {
  padding: 28px;
}
.pad-29 {
  padding: 29px;
}
.pad-30 {
  padding: 30px;
}
.pad-top-0{
      padding-top: 0px;
}
.pad-top-1{
      padding-top: 1px;
}
.pad-top-2{
      padding-top: 2px;
}
.pad-top-3{
      padding-top: 3px;
}
.pad-top-4{
      padding-top: 4px;
}
.pad-top-5{
      padding-top: 5px;
}
.pad-top-6{
      padding-top: 6px;
}
.pad-top-7{
      padding-top: 7px;
}
.pad-top-8{
      padding-top: 8px;
}
.pad-top-9{
      padding-top: 9px;
}
.pad-top-10{
      padding-top: 10px;
}
.pad-top-11{
      padding-top: 11px;
}
.pad-top-12{
      padding-top: 12px;
}
.pad-top-13{
      padding-top: 13px;
}
.pad-top-14{
      padding-top: 14px;
}
.pad-top-15{
      padding-top: 15px;
}
.pad-top-16{
      padding-top: 16px;
}
.pad-top-17{
      padding-top: 17px;
}
.pad-top-18{
      padding-top: 18px;
}
.pad-top-19{
      padding-top: 19px;
}
.pad-top-20{
      padding-top: 20px;
}
.pad-top-21{
      padding-top: 21px;
}
.pad-top-22{
      padding-top: 22px;
}
.pad-top-23{
      padding-top: 23px;
}
.pad-top-24{
      padding-top: 24px;
}
.pad-top-25{
      padding-top: 25px;
}
.pad-top-26{
      padding-top: 26px;
}
.pad-top-27{
      padding-top: 27px;
}
.pad-top-28{
      padding-top: 28px;
}
.pad-top-29{
      padding-top: 29px;
}
.pad-top-30{
      padding-top: 30px;
}
.pad-top-31{
      padding-top: 31px;
}
.pad-top-32{
      padding-top: 32px;
}
.pad-top-33{
      padding-top: 33px;
}
.pad-top-34{
      padding-top: 34px;
}
.pad-top-35{
      padding-top: 35px;
}
.pad-top-36{
      padding-top: 36px;
}
.pad-top-37{
      padding-top: 37px;
}
.pad-top-38{
      padding-top: 38px;
}
.pad-top-39{
      padding-top: 39px;
}
.pad-top-40{
      padding-top: 40px;
}
.pad-top-41{
      padding-top: 41px;
}
.pad-top-42{
      padding-top: 42px;
}
.pad-top-43{
      padding-top: 43px;
}
.pad-top-44{
      padding-top: 44px;
}
.pad-top-45{
      padding-top: 45px;
}
.pad-top-46{
      padding-top: 46px;
}
.pad-top-47{
      padding-top: 47px;
}
.pad-top-48{
      padding-top: 48px;
}
.pad-top-49{
      padding-top: 49px;
}
.pad-top-50{
      padding-top: 50px;
}
.pad-bot-0{
      padding-bottom: 0px;
}
.pad-bot-1{
      padding-bottom: 1px;
}
.pad-bot-2{
      padding-bottom: 2px;
}
.pad-bot-3{
      padding-bottom: 3px;
}
.pad-bot-4{
      padding-bottom: 4px;
}
.pad-bot-5{
      padding-bottom: 5px;
}
.pad-bot-6{
      padding-bottom: 6px;
}
.pad-bot-7{
      padding-bottom: 7px;
}
.pad-bot-8{
      padding-bottom: 8px;
}
.pad-bot-9{
      padding-bottom: 9px;
}
.pad-bot-10{
      padding-bottom: 10px;
}
.pad-bot-11{
      padding-bottom: 11px;
}
.pad-bot-12{
      padding-bottom: 12px;
}
.pad-bot-13{
      padding-bottom: 13px;
}
.pad-bot-14{
      padding-bottom: 14px;
}
.pad-bot-15{
      padding-bottom: 15px;
}
.pad-bot-16{
      padding-bottom: 16px;
}
.pad-bot-17{
      padding-bottom: 17px;
}
.pad-bot-18{
      padding-bottom: 18px;
}
.pad-bot-19{
      padding-bottom: 19px;
}
.pad-bot-20{
      padding-bottom: 20px;
}
.pad-bot-21{
      padding-bottom: 21px;
}
.pad-bot-22{
      padding-bottom: 22px;
}
.pad-bot-23{
      padding-bottom: 23px;
}
.pad-bot-24{
      padding-bottom: 24px;
}
.pad-bot-25{
      padding-bottom: 25px;
}
.pad-bot-26{
      padding-bottom: 26px;
}
.pad-bot-27{
      padding-bottom: 27px;
}
.pad-bot-28{
      padding-bottom: 28px;
}
.pad-bot-29{
      padding-bottom: 29px;
}
.pad-bot-30{
      padding-bottom: 30px;
}
.pad-bot-31{
      padding-bottom: 31px;
}
.pad-bot-32{
      padding-bottom: 32px;
}
.pad-bot-33{
      padding-bottom: 33px;
}
.pad-bot-34{
      padding-bottom: 34px;
}
.pad-bot-35{
      padding-bottom: 35px;
}
.pad-bot-36{
      padding-bottom: 36px;
}
.pad-bot-37{
      padding-bottom: 37px;
}
.pad-bot-38{
      padding-bottom: 38px;
}
.pad-bot-39{
      padding-bottom: 39px;
}
.pad-bot-40{
      padding-bottom: 40px;
}
.pad-bot-41{
      padding-bottom: 41px;
}
.pad-bot-42{
      padding-bottom: 42px;
}
.pad-bot-43{
      padding-bottom: 43px;
}
.pad-bot-44{
      padding-bottom: 44px;
}
.pad-bot-45{
      padding-bottom: 45px;
}
.pad-bot-46{
      padding-bottom: 46px;
}
.pad-bot-47{
      padding-bottom: 47px;
}
.pad-bot-48{
      padding-bottom: 48px;
}
.pad-bot-49{
      padding-bottom: 49px;
}
.pad-bot-50{
      padding-bottom: 50px;
}
.pad-left-1{
      padding-left: 1px;
}
.pad-left-2{
      padding-left: 2px;
}
.pad-left-3{
      padding-left: 3px;
}
.pad-left-4{
      padding-left: 4px;
}
.pad-left-5{
      padding-left: 5px;
}
.pad-left-6{
      padding-left: 6px;
}
.pad-left-7{
      padding-left: 7px;
}
.pad-left-8{
      padding-left: 8px;
}
.pad-left-9{
      padding-left: 9px;
}
.pad-left-10{
      padding-left: 10px;
}
.pad-left-11{
      padding-left: 11px;
}
.pad-left-12{
      padding-left: 12px;
}
.pad-left-13{
      padding-left: 13px;
}
.pad-left-14{
      padding-left: 14px;
}
.pad-left-15{
      padding-left: 15px;
}
.pad-left-16{
      padding-left: 16px;
}
.pad-left-17{
      padding-left: 17px;
}
.pad-left-18{
      padding-left: 18px;
}
.pad-left-19{
      padding-left: 19px;
}
.pad-left-20{
      padding-left: 20px;
}
.pad-left-21{
      padding-left: 21px;
}
.pad-left-22{
      padding-left: 22px;
}
.pad-left-23{
      padding-left: 23px;
}
.pad-left-24{
      padding-left: 24px;
}
.pad-left-25{
      padding-left: 25px;
}
.pad-left-26{
      padding-left: 26px;
}
.pad-left-27{
      padding-left: 27px;
}
.pad-left-28{
      padding-left: 28px;
}
.pad-left-29{
      padding-left: 29px;
}
.pad-left-30{
      padding-left: 30px;
}
.pad-left-31{
      padding-left: 31px;
}
.pad-left-32{
      padding-left: 32px;
}
.pad-left-33{
      padding-left: 33px;
}
.pad-left-34{
      padding-left: 34px;
}
.pad-left-35{
      padding-left: 35px;
}
.pad-left-36{
      padding-left: 36px;
}
.pad-left-37{
      padding-left: 37px;
}
.pad-left-38{
      padding-left: 38px;
}
.pad-left-39{
      padding-left: 39px;
}
.pad-left-40{
      padding-left: 40px;
}
.pad-left-41{
      padding-left: 41px;
}
.pad-left-42{
      padding-left: 42px;
}
.pad-left-43{
      padding-left: 43px;
}
.pad-left-44{
      padding-left: 44px;
}
.pad-left-45{
      padding-left: 45px;
}
.pad-left-46{
      padding-left: 46px;
}
.pad-left-47{
      padding-left: 47px;
}
.pad-left-48{
      padding-left: 48px;
}
.pad-left-49{
      padding-left: 49px;
}
.pad-left-50{
      padding-left: 50px;
}
.pad-right-1{
      padding-right: 1px;
}
.pad-right-2{
      padding-right: 2px;
}
.pad-right-3{
      padding-right: 3px;
}
.pad-right-4{
      padding-right: 4px;
}
.pad-right-5{
      padding-right: 5px;
}
.pad-right-6{
      padding-right: 6px;
}
.pad-right-7{
      padding-right: 7px;
}
.pad-right-8{
      padding-right: 8px;
}
.pad-right-9{
      padding-right: 9px;
}
.pad-right-10{
      padding-right: 10px;
}
.pad-right-11{
      padding-right: 11px;
}
.pad-right-12{
      padding-right: 12px;
}
.pad-right-13{
      padding-right: 13px;
}
.pad-right-14{
      padding-right: 14px;
}
.pad-right-15{
      padding-right: 15px;
}
.pad-right-16{
      padding-right: 16px;
}
.pad-right-17{
      padding-right: 17px;
}
.pad-right-18{
      padding-right: 18px;
}
.pad-right-19{
      padding-right: 19px;
}
.pad-right-20{
      padding-right: 20px;
}
.pad-right-21{
      padding-right: 21px;
}
.pad-right-22{
      padding-right: 22px;
}
.pad-right-23{
      padding-right: 23px;
}
.pad-right-24{
      padding-right: 24px;
}
.pad-right-25{
      padding-right: 25px;
}
.pad-right-26{
      padding-right: 26px;
}
.pad-right-27{
      padding-right: 27px;
}
.pad-right-28{
      padding-right: 28px;
}
.pad-right-29{
      padding-right: 29px;
}
.pad-right-30{
      padding-right: 30px;
}
.pad-right-31{
      padding-right: 31px;
}
.pad-right-32{
      padding-right: 32px;
}
.pad-right-33{
      padding-right: 33px;
}
.pad-right-34{
      padding-right: 34px;
}
.pad-right-35{
      padding-right: 35px;
}
.pad-right-36{
      padding-right: 36px;
}
.pad-right-37{
      padding-right: 37px;
}
.pad-right-38{
      padding-right: 38px;
}
.pad-right-39{
      padding-right: 39px;
}
.pad-right-40{
      padding-right: 40px;
}
.pad-right-41{
      padding-right: 41px;
}
.pad-right-42{
      padding-right: 42px;
}
.pad-right-43{
      padding-right: 43px;
}
.pad-right-44{
      padding-right: 44px;
}
.pad-right-45{
      padding-right: 45px;
}
.pad-right-46{
      padding-right: 46px;
}
.pad-right-47{
      padding-right: 47px;
}
.pad-right-48{
      padding-right: 48px;
}
.pad-right-49{
      padding-right: 49px;
}
.pad-right-50{
      padding-right: 50px;
}
.pad-0-15{
      padding: 0 15px
}
.cfff{
  color: #fff;
}
.c333{
  color: #333;
}
.c444{
  color: #444;
}
.c555{
  color: #555;
}
.c777{
  color: #777;
}
.c0E202E{
  color: #0E202E;
}
.c2E2E2E{
  color: #2E2E2E;
}
.c807979{
  color: #807979;
}
.c3A4149{
  color: #3A4149;
}
.c555151{
  color: #555151;
}
.c15C9B7{
  color: #15C9B7;
}
.c90989F{
  color: #90989F;
}
.cB7BECB{
  color: #B7BECB
}
.c0133CA{
  color: #0133CA;
}
.c744F11{
  color: #744F11
}
.c2C2A2A{
  color: #2C2A2A;
}
.c818C9E{
  color: #818C9E
}
.c0F7CED{
  color: #0F7CED;
}
.c849CBD{
  color: #849CBD
}
.c084484{
  color: #084484;
}
.c3F3D3D{
  color: #3F3D3D;
}
.c001F7D{
  color: #001F7D;
}
.c0066FF{
  color: #0066FF;
}
.c98A2B4{
  color: #98A2B4
}
.cACB2BA{
color:  #ACB2BA
}
.c6C7184{
color:  #6C7184
}
.c485473{
color:  #485473
}
.bg0133CA{
  background-color: #0133CA;
}
.bg0133CA{
  background-color: #F6F7F9;
}
.error-red-txt{
  color: #EB5757
}
.rotate-180{
    transform: rotate(180deg);
}
.view-more--light {
  color: $white;

  &:hover {
    color: rgba($white, 0.8);
  }
}
.no-back{
  .back-arrow{
    display: none !important;
  }
  .head-logo{
    margin-left: 12px;
  }
}


.card-body {
  .view-more {
    padding: 1rem 0 0;
  }
}

.rounded-50{
  border-radius: 50px;
}

//pagination
.pagination-block{
  margin: 60px 0;
  .page-number{
    width: 30px;
    height: 30px;
    color: #98A2B4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: auto;
    margin: 0 10px;
  }
  .page-number.active{
    width: 30px;
    background: #0133CA;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    color: #fff;

  }
  .dotdotdot{
    color: #98A2B4;
    margin: 0 7px;
  }
  .prev-nxt{
    margin: 0 10px;
  }

  .prev-ic{
    margin-right: 6px;
  }
  .next-ic{
    margin-left: 6px;
  }
  .fir-lst{
        margin: 0 10px;
  }
}
.rad-10{
  border-radius: 10px;
}
.round-btn{
  // background-color: $mainColor !important;
  border-radius: 20px;
      min-width: 80px;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.home-cards {
  .slick-list{
        padding: 0px 15px !important;
  }
  .slick-slide{
    padding: 3px;
  }
  .slider-nav .slick-slide img {
    border-radius: 8px;
    max-height: 100%;
    min-height: 115px;
    // border: 1px solid #ccc;
  }
  .slick-slide img {
    -o-object-fit: fill;
    object-fit: fill;
    min-height: 100%;
    -o-object-fit: unset!important;
    width: 100%;
    height: 100%;
  }
}
.active-cat{
  border-color: $mainColor !important;
}
.modal-backdrop{
  background-color: #424242;
}
.modal-backdrop.show {
    opacity: .7;
}
.promo-cards .slick-list {
    padding: 0px 45px !important;
}
 .promo-cards .slick-track{
      margin-left: -37px;
}
.ql-container {
    height: 120px !important;
}
.gift-top-bar .ng-select-container {
    height: 42px !important;
    border-bottom: 0;
    padding: 0 10px;
    font-size: 15px;
}
.ng-select .ng-arrow-wrapper {
    top: -14px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    right: -8px;
}
.offer-edit .ng-select .ng-arrow-wrapper{
      right: -5px;
      top: -11px;
}
.ng-select:before, .ng-select:after{
  height: 0 !important;
  display: none;
}
.ng-select.ng-select-single .ng-select-container{
  border-color: transparent !important;
  background-color: transparent;
}
.multiselect-dropdown .dropdown-btn .dropdown-down{
  position: relative !important;
  top: -1px !important;
  right: -7px !important;
    border-top: 6px solid #cecece !important;
    border-left: 6px solid transparent !important;
    border-right: 6px solid transparent !important;
}
.multiselect-dropdown .dropdown-btn .dropdown-up{
  position: relative !important;
  top: -1px !important;
  right: -7px !important;
border-bottom: 6px solid #cecece !important;
border-left: 6px solid transparent !important;
border-right: 6px solid transparent !important;
}
.multiselect-dropdown .dropdown-btn{
  border: 1px solid #ddd !important;
  height: 36px;
}
.ql-container.ql-snow, .ql-toolbar.ql-snow{
      border-color: #ddd !important;
}
.ql-toolbar.ql-snow{
      border-radius: 4px 4px 0 0;
}
.ql-container.ql-snow{
      border-radius: 0px 0px 4px 4px;
}
ng-select input {
  color: transparent;
  text-shadow: 0 0 0 #2196f3;

  &:focus {
      outline: none;
  }
}
.modal-backdrop{
      background: rgba(58, 54, 96, 0.9);
}
.modal-backdrop.show {
    opacity: 1;
}
.slick-arrow{
  background: none;
  outline: none;
  border: 0;
  position: absolute;
  font-size: 0;
  bottom: 0
}
.category-cards{
  .slick-list{
    // padding: 0 50px 0 0 !important;
    margin-left: -5px;
  }
  .slick-arrow{
    display: none;
  }
}
.home-cards {

}
.category-cards .slide{
      // width: 92px !important;
}
// for drag scrollbar
.promo-cards .drag-scroll-content{
  // overflow: hidden !important;
  padding-top: 3px;
}
.li-margin-bottom-12{
  li{
    margin-bottom: 12px;
  }
  ul, ol{
  padding-left: 15px;
  }
}
[data-homepage="true"]{
  .back-arrow, .niyo-head-title{
    display: none;
  }
  .niyo-home-logo{
    display: block;

  }

}

//modal background
.modal-backdrop {
    background: rgba(76, 73, 111, 0.98);
}
// home page
.category-cards .drag-scroll-content{
  text-align: center;
}
// Dragula ends
// Dashboard
//invalid tooltips
.invalid-tooltip-box{
  .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before{
        border-bottom-color: #D95C5C;
  }
  .tooltip-inner{
    background-color: #D95C5C;
  color: #fff;
  }
}
.modal-backdrop{
      background: rgba(14, 45, 72, 0.98);
}
// .multi-d-in p:last-child, .no-last-p p:last-child{
//   display: none;
// }
.offer-img{
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 1px 0 rgba(227, 227, 227, 0.3), 0 1px 2px 1px rgba(205, 205, 205, 0.15);
  img{
    border-radius: 7px 7px 0 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}
.new-cat-offer-contain{
  .slick-track{
    float: left;
  }
  .slick-arrow{
    display: none;
  }
}
.somethng-selected{
  .not-selected{
    display: none;
  }
}
.slide-menu-content a{
  color: #4BA5E7;
}

.carousel-indicators{
  // margin-bottom: -34px;
  li{
    background: #CCD0DB;
    width: 6px;
    height: 6px;
    opacity: 1;
    border: 0;
    border-radius: 50%;
    &.active{
      background: #0D0774;
      width: 8px;
      height: 8px;
      margin-top: -1px;
    }
  }
}
// .carousel-control{
//   display: none;
// }
.carousel-control-prev-icon:before, .carousel-control-prev-icon:after, .carousel-control-next-icon:before, .carousel-control-next-icon:after{
  content: "" !important;
}
.carousel-control-prev, .carousel-control-next{
  width: 6% !important;
}
carousel.slide-animated {
  slide.item {
    animation-duration: 1s;
    animation-fill-mode: both;
    &.active {
      width: 100%;
      animation-name: carouselSlideIn;
    }
    &:not(.active) {
      display: table-cell;
      animation-name: carouselSlideOut;
    }
  }
}

@keyframes carouselSlideIn {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0); }
}
@keyframes carouselSlideOut {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}
//header
.small-head{
  display: none;
}
.small-header{
  .small-head{
    display: flex;
  }
  .full-head{
    display: none;
  }
}
@media(max-width: 768px) {
  .hidden-mob{
    display: none;
  }
  .full-mob{
    width: 100% !important;
  }
  .no-padding-mob{
    padding: 0;
  }
}
@media(min-width: 768px) {
  .hidden-desktop{
    display: none;
  }

}

#main-outer[data-client="hdfcbankdinersclub"] {
  .mobile-banner{
    height: 36vw;
    img{
      object-fit: cover;
      height: 100%;
      width: 100%;
      object-position: 63%;
    }
  }
}
// niyo client specific
#main-outer[data-client="niyo"] {
  .niyo-app-info-header, .niyo-app-list-header .search-box{
    display: none;
  }

  .couponmodal{
    .merchant-logo-contain{
      border-bottom: 1px solid #ddd;
      img{
          height: 30px;
      }
    }
    .coupon-code-pop{
        color: #0F0F0F;
    }
    .divider-top{
      margin-top: -7px;
      img{
        height: 0;
        border-top: 1px dashed #222;
      }
    }
    .divider-bottom{
      img{
        height: 0;
        border-top: 1px dashed #222;
      }
    }
    #validity_coupon_code_return, .coupon-code-footer .f300{
      color: #0F0F0F;
      font-weight: 400;
    }
    .shop_now_url{
      color: rgb(255, 255, 255);
      background-color: #E21F2B;
      border-radius: 4px;
    }
    .coupan-code-box{
      background: #EBEBEB;
      color: #0F0F0F;
      font-weight: 400;
      border: 1px solid #ddd;
    }
    .copy-icon svg path{
      fill: #E21F2B;

    }
    .copy-icon svg.active path {
      fill: green !important;
    }
  }
  .details-back{
    position: absolute;
    top: 0;
    left: 5px;
    z-index: 1;
    padding: 5px 10px;
    display: block !important;
    img{
      width: 14px;
    }
  }
  .showLogin{

      .logo img{
            max-width: 90px;
      }

  }
  .niyo-home-logo{
    img{
      width: 94px;
      margin-top: 6px;
    }
  }
}
#main-outer[data-client="oneassist"] {
  .drop-txt{
      color: #14549a;
  }
  .profile-box{
    .btn-group.dropdown{
      svg path{
        fill: #14549a;
      }
    }
  }
  .subCatContain{
    display: none;
  }
  .couponmodal .modal-bg{
        background: rgb(255, 255, 255) !important;
        .offer-text{
              color: #333;
              font-weight: 600;
        }
        #validity_coupon_code_return{
          color: #555;
          font-weight: 400;
        }
        #redemption_coupon_code{
          background-color: rgba(168, 183, 195, 0.2) !important;
          color: #333;
          svg path{
            fill: #333 !important;
          }
          svg.active path{
            fill: #14549a !important;
          }
        }
        .coupon-code-footer{
          color: #555;
          .f300{
            font-weight: 500;
          }
        }
        .go-to{
          .shop_now_url{
            background: rgb(20, 84, 154);
            color: #fff !important;
          }
        }
        .merchant-logo-contain{
          border-bottom: 1px solid rgba(145, 174, 199, 0.14);
        }
        .merchant-logo-contain{
          img{
              height: 36px;
          }
        }
  }
}
#main-outer[data-client="kotak-mahindra-bank"] {
  .showLogin{
    .close-box {
      height: 35px;
    }
  }
}
.au-help{
  display: flex !important;
  margin-right: 20px;
  .help-drop{
    padding: 20px !important;
    width: 270px !important;
    left: initial !important;
    right: 0px !important;
    background: rgb(246, 248, 251);
    color: rgb(14, 32, 46);
    font-weight: 400;
    font-size: 12px;
    top: 40px !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
    p{
      line-height: 1.7;
    }
    .icon-box{
      width: 35px;
    }
  }
  .just-link{
    a{
      color: #0E202E !important;
      font-size: 14px !important;
    }
    a:hover, a:focus{
      text-decoration: none;
    }
  }
}
// AU client specific
#main-outer[data-client="au-bank"] {
  $auPurple: rgb(103, 36, 116);
  $auOrange: #ED6C37;
  .main-parent .d-single-off{
    margin-bottom: 30px;
    margin-top: 25px;
  }
  .main-parent .single-offer{
      border-radius: 100px 6px 6px 6px;
      box-shadow: 0px 17px 48px rgba(0, 0, 0, 0.1);
      margin: 0;
      height: 100%;
      .claim-btn{
        border-radius: 6px;
      }
      .offer-rtxt-data{
        background: $auPurple;
            height: 100%;
      }
      .offer-desc{
        color: #fff;
        font-weight: 400;
        font-size: 16px;
      }
      .offer-title{
        color: #fff;
      font-size: 26px;
      }
      .know-btn{
        color: $auOrange;

      }
  }
  .login-btn{
    display: none;
  }
  .login-btn-au{
    // height: 70px;
    margin: -10px 0 -9px;
    padding: 0 13px;
    display: flex !important;
    align-items: center;
    height: 39px;
    border-radius: 10px;
    background: $auOrange;
      svg{
        width: 20px;
        height: 20px;
      }
      span{
        margin-left: 8px;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
      }
  }
  .login-btn-au:hover, .login-btn-au:focus{
    background: #55215b;
    background: $auOrange;
  }


  .offer-below-row-parent{
    background: $auPurple;
  }
  .offer-below-lft{
    color: #fff;
    svg path{
      fill: #fff;
    }
  }
  .terms-block{
    color: #fff;
  }
  .subCatContain{
    display: none;
  }
  .d-c-title{
    svg path{
      stroke: #333;
    }
  }
  .single-category.active{
    .d-c-title{
      svg path{
        stroke: $auPurple;
      }
    }
  }
  @media (min-width: 992px){
    .signup-contain{
    min-width: 280px;

    }
  }
  @media (max-width: 992px){
    .au-home-icon{
      display: none;
    }
    .search-container{
      width: 55%;
    }
    .search-container{
      width: 100%;
      padding-left: 20px;

    }
  }
  @media (min-width: 768px){
    .search-box{
        padding: 7px 40px 9px;
    }
    .main-parent{
      padding-top: 45px;
    }
    .cat-selected{
      margin-top: 10px;
    }
  }
}
.circle-design{
  display: none;
}
.modal-overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
// visa client specific
#main-outer[data-client="visa-offers"], #main-outer[data-client="visaoffers"] {
  .couponmodal{
    .merchant-logo-contain{
      img{
            max-width: 60px;
            margin: 5px 0 15px
      }
    }
  }
  .single-top-img-container,.ban-container{
    min-height: 350px;
  }
  .d-c-title{
    text-transform: capitalize;
    font-size: 16px;
  }
  .banner-overlay {
    h1{
      font-size: 44px;
    }
    p{
      font-size: 19px;
    }
  }
  header{
    background: linear-gradient(to left,#1a1f71,#0023a0) !important;
  }
  .showLogin ,.addCardModal{
    .login-client-logo{
      width: 80px;
    }

  }
  .close-box{
    img{
      max-width: 80px;
    }
  }
  .add-new .availed-offers-info{
    font-weight: 600;
    color: #333;
    .add-card-label{
          padding-left: 5px;
    }
  }
  .couponmodal .coupan-code-box{
    background-color: rgba(7, 24, 226, 0.42);
  }
  .circle-design{
    background: #14151b;
  }
  .coupon-client-logo{
    height: 24px;
  }
  header .inp-search{
    background-color: rgba(7, 24, 226, 0.42);
  }
  .main-parent{
    margin-top: -85px;
    background: #fff;
    position: relative;
    border-radius: 5px;
    padding: 45px 30px;
    .single-offer{
      border-radius: 8px;
    }
    .claim-btn{
      border-radius: 5px;
    }
    .offers-contain{
      border: 0;
    }
  }
}
.form-control:focus{
  box-shadow: inherit;
  background-color: inherit;
}
#main-outer[data-client="smeoffers"] {
  .template-2-header .head-logo{
    margin-top: -3px;
    img{
      height: 56px;
    }
  }
  .desktop .main-parent{
    background: #fff;
    margin-top: 50px;
    position: relative;
    padding: 20px 30px 50px;
    border-radius: 3px 3px 0 0;
  }


  .close-box{
    img{
      max-width: 235px;
    }
  }
  .showLogin{
    .logo{
          height: 70px;
        margin: -15px 0 0 -5px;
    }
  }
  .couponmodal{
    .merchant-logo-contain{
      padding: 0;
      margin: -10px -25px 0;
      img{
        margin: 10px;
        height: 55px;
      }
    }
    .coupon-code-main{
      background-image: linear-gradient(to right, #5f5f5f 33%, rgba(255,255,255,0) 0%);
      background-position: top;
      background-size: 11px 1px;
      background-repeat: repeat-x;
      padding: 20px 24px 15px;
      margin-top: 5px;
    }
    .divider-top,.divider-bottom{
      display: none;
    }
    .coupon-code-footer {
      background-image: linear-gradient(to right, #5f5f5f 33%, rgba(255, 255, 255, 0) 0%);
      background-position: top;
      background-size: 11px 1px;
      background-repeat: repeat-x;
      margin: 0 -25px;
      padding-top: 25px;
      .f300{
        font-weight: 400;
        opacity: .8;
        padding: 0 25px 15px;
      }
    }
     .modal-bg .validity-box small{
       font-size: 12px;
       font-weight: 400;
     }
  }
}
#main-outer[data-client="american-express"]{
  .large-banner{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .apply-btn {

    margin-left: 15px;
    background-color: #0b89c7;
    color: #fff;
    width: 120px;
    font-weight: 500;
    font-size: 14px;
    height: 36px;
  }
  .sub-cat-btn{
    padding: 0 15px;
    font-size: 16px;
  }
  .sub-cat-btn:hover,.sub-cat-btn:focus{
    text-decoration: underline;
  }
  // .search-container{
  //   width: 35%;
  // }
  .amex-right {
    width: 40%;
  }
  .main-parent .category-contain{
    .single-category{
      display: none ;
    }
  }
  .banner-overlay{
    h1{
      margin-top: -30px;
    }


  }
  .binmodal{
    .modal-content{
      background-color: #000;
      padding-top: 35px;
      .logo{
        text-align: center;
        margin-bottom: 30px;
        img{
          width: 165px;
        }
      }
      .title{
        color: #eee;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
      }
      .form-control{
          background-color: transparent;
          color: #eee;
      }
      .main-flex{
        background-color: #212121;
        border: 1px solid rgba(56, 56, 56, 0.5);
      }
    }

    .step-three{
      .last-b{
        color: #ccc;
        background: #3a3a3a;
      }
      .btn-theme{
          margin: 40px 0 10px;
      }
    }

  }

  @media (max-width: 768px){
    .search-container{
      width: 100%;
    }
    .logo-contain img{
        max-width: 145px;
    }
    .search-box input{
      font-size: 13px;
    }
    .search-category-contain{
      display: none !important;
    }
  }
}
#main-outer[data-client="dinersclub"]{
  .s-more{
    color: rgb(17, 77, 151) !important;
  }
  header{

    box-shadow: 0px 0px 3px #ddd;
    .search-inp {
      border: 1px solid #0F4E97;
    }
    .se-ic path{
      fill: #0F4E97;
    }
    .discover-logo{
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .search-placeholder{
      color:#333;
    }
    .search-placeholder::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #999;
      opacity: 1; /* Firefox */
    }
    .search-placeholder:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #999;
    }
    .search-placeholder::-ms-input-placeholder { /* Microsoft Edge */
      color: #999;
    }
  }
  .diners-logos{
    .dinners-logo{
          height: 30px;
    }
    .hdfc-logo{
      height: 38px;
    }
  }
  .coupan-code-box{
      // background-color: rgb(1, 64, 113);
  }
  @media (max-width: 768px){
    .page-head {
      .logo-contain img{
        width: 95px;
      }

    }
    .search-container{
      display: none;
    }
    .discover-logo-mob img{
      width: 70px;
      margin-top: 5px;
    }
    .back-arrow{
      margin-left: -7px;
      margin-right: 2px;
      svg path{
        fill: rgb(17, 77, 151);
      }
    }
  }
  body{
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
  }
  body::-webkit-scrollbar {
      display: none;
  }
}
#main-outer[data-client="citi"]{
  .showLogin .close-box.logo{
    display: none;
  }
  .to-show.merchant-desc{
    line-height: 1.6;
  }
  .subCatContain, .category-bottom-line, .category-contain{
        display: none;
  }
  .know-btn{
    color: #195993 !important;
  }
  .ban-container{
    height: 34vw;
    min-height: 380px;
    .large-banner{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  .logo-title{
    font-size: 12px;
    align-items: center;
    padding-top: 5px;
  }
  .c-line{
    display: none;
  }
  .privileges{
    border-left: 1px solid #fff;
  }

  .main-parent {
    .d-single-off{
      flex: 0 0 50%;
      max-width: 50%;
    }
    .single-offer{
      border-radius: 4px;
    }
    .offer-img {
      height: 300px;
    }
    .merchant-logo{
      display: none;
    }
    .offer-rtxt-data{
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      box-shadow: inset 0 -120px 38px -23px rgba(0, 0, 0, 0.6);
      padding: 0;
    }
    .offer-title {
      color: #fff;
      font-size: 25px;
      font-weight: 600;
      width: 100%;
      padding: 20px 20px 0;

    }
    .desc-box{
          width: 100%;
          padding: 0px 20px 0;

    }
    .offer-desc{
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      height: auto;
      padding-bottom: 14px;
      padding-top: 12px;
    }
    .claim-block{
      display: none;
    }
  }
  .loading-blocks{
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 768px){
    .slick-arrow{
      display: none;
    }
    .page-head .logo-contain img {
    max-height: 29px;
    }
    .banner-overlay h1 {
    line-height: 1.6;
    color: #fff;
    max-width: 1000px;
    font-weight: 600;
    font-size: 26px;
    margin-top: -40px;
    }
    .banner-categories .banner-category{
      padding: 8px 15px;
      border-radius: 3px;
      font-size: 13px;
    }
  }
}
#main-outer[data-client="janabank"]{
  .desktop{
      background: #f5f3f3;
  }
}
#main-outer[data-client="rupay-smebenefits"]{
  header {
    border-top: 4px solid #f37b21;
    .logo-contain{
      img{
          margin-top: 3px;
      }
    }
  }

  .large-banner{
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .showLogin{
    .logo{
        margin: 0;
        .login-client-logo{
            max-width: 100px;
        }
    }

  }
  .subCatContain {
    display: none;
  }
  @media(max-width: 768px) {
    .page-head .logo-contain img {
    max-height: 25px;
    }
  }
}
#main-outer[data-client="axis-bank"]{
  #offers{
    .category-contain,.category-bottom-line,.subCatContain{
      display: none;
    }
  }
  .s-more{
    color: #ae275f!important
  }
}
.default-dropdown-menu{
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 0;
  padding: 8px;
  .option{
   padding: 7px 15px;
  }
  .option:hover,.option:focus{
    background-color: #f2f2f2;
  }
}
